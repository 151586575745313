module.exports = {
  server: "CWOPT",
  serverTokenUrl: "https://opt-services.mycw.in/cw-identity/oauth/token",
  serverUrl: "https://opt-retail-services.mycw.in/cw-retail/graphql",
  CWCoreServicesUrl: "https://opt-services.mycw.in/back-office-2.0/graphql",
  edcUrl: "http://localhost:80/innoweb/api/MSwipe",
  innovitiPaymentCodes: {
    Card: 0,
    GPay: 86,
    Sodexo: 90,
    UPI: 86,
  },
  fileDownloadUrl: 'https://opt-retail-services.mycw.in:8445/'
};
