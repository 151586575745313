import axios from "axios";
import { currencyTypes } from "../../constants/taxCurrencies";
import _ from "lodash";
import moment from "moment"

export const kotPrinter = (printReciptData,receiptType) => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const currenciesList = tillData.tillAccess.csBunit.currencies;
  const printerURL = tillData.tillAccess.cwrTill.hardwareController.imageUrl;
  let selectedTableData = JSON.parse(localStorage.getItem("tableName"));

let printData = Object.entries(printReciptData).map((element, index)=>{
  if(element[1].length>0){
   let newData = element[1].reduce((result, current) => {
      const category = current.productCategoryName;
      if (!result[category]) {
        result[category] = [];
      }
      if(current.order === "N"){
      result[category].push(current);
      }
      return result;
    }, {});
    element.newData = newData
  }else{

  }
  return element
});

let data = "";
printData.map((item)=>{ 
  Object.entries(item?.newData).map((ele,index)=>{
  let uniqueRates = new Set();
  if(ele[1].length>0 && !uniqueRates.has(index)){
  let printDataXml = tillData.tillAccess.cwrTill.kotPrintTemplate.xmlcode2;
  printDataXml = printDataXml.replace("<output>", "");
  printDataXml = printDataXml.replace("</output>", "");
   printDataXml = printDataXml.replace("@@Printer@@", `${item[0]}`);
   printDataXml = printDataXml.replace("@@OrderNumber@@", `KOT${localStorage.getItem("orderId")}`);
   printDataXml = printDataXml.replace("@@name@@", `${selectedTableData.name === undefined ? "" : selectedTableData.name}`);
   printDataXml = printDataXml.replace("@@sectionName@@", `${selectedTableData.sectionName === undefined ? "" : selectedTableData.sectionName }`);
   printDataXml = printDataXml.replace("@@date@@", `${moment(new Date()).format("YYYY-MM-DD")}`);
   printDataXml = printDataXml.replace("@@time@@", `${moment(new Date()).format("HH:mm")}`);
   printDataXml = printDataXml.replace("@@mproductcategory@@", `${ele[0]}`);
    let newData = "";
    ele[1]?.map((element) => {
      newData += `
      <line>
      <text align="left" length="15" style="text-align: left; padding-right: 10px;">${element.name}</text>
         <text align="right" length="8">${element.weight}</text>
         <text align="right" length="14">${element?.notes === undefined ? "" :  element?.notes}</text>
     </line>
      `
      }); 
   printDataXml = printDataXml.replace("@@printReciptDataItems@@", `${newData}`);
   data += printDataXml;
  }
  });
});
  // let index = 0
  // if(index === 0){
  //   index++;
    let finalValue = `<?xml version="1.0" encoding="UTF-8"?><output>${data}</output>`
    axios
      .post(`${printerURL}printer`, finalValue.toString(), {
        headers: { "Content-Type": "text/plain" },
      })
      .then(() => {
        console.log("XML print success !");
      })
      .catch((response) => {
        console.log("XML print failed !",response);
      });
  // }
};
