import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, Divider, Button, message, Tooltip, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import _ from "lodash";
import { Scrollbars } from "react-custom-scrollbars";
import { useHistory } from "react-router-dom";
import { serverUrl } from "../../../constants/config";
import Axios from "axios";
import { v4 as uuidv4 } from "uuid";
import BarCodeComponent from "../../../lib/BarCodeComponet";
import InfiniteScroll from "react-infinite-scroll-component";
import AddCircle from "../../../assets/images/add-circle.svg";
import cwPosLogo from "../../../assets/images/cwpos-green.png";
import MenuIcon from "../../../assets/images/menu.png";
import All from "../../../assets/images/all.svg";
import ParkedOrder from "../../../assets/images/parkedOrder.svg";
import InProgress from "../../../assets/images/inProgress.svg";
import Pending from "../../../assets/images/pending.svg";
import Prepared from "../../../assets/images/prepared.svg";
import Completed from "../../../assets/images/completed.svg";
import Dinner from "../../../assets/images/dinner.svg";
import Bag from "../../../assets/images/bag.svg";
import AddCust from "../../../assets/images/add-customer.svg";
import EmptyChair from "../../../assets/images/table-chair.svg";
import DeleteGreen from "../../../assets/images/delete-green.svg";
import InvoiceGreen from "../../../assets/images/invoice-green.svg";
import SettingsGreen from "../../../assets/images/settings-btn.svg";
import OffersGreen from "../../../assets/images/offers-green.svg";
import NotesLine from "../../../assets/images/note-line.svg";
import data from "../../../constants/restaurantPos.json";
import db from "../../../database";
import { clock } from "../../../utility/clock";
import RestaurantModals from "./RestaurantModals";
import Timer from "../../../assets/images/timer.svg";
import TextArea from "rc-textarea";
import Keyboard from "react-simple-keyboard";
import moment from "moment"
import { htmlPrint } from "../../../lib/printer/htmlPrinter";
import { kotPrinter } from "../../../lib/printer/kotPrinter";

const FBLayout = (props) => {
  const {
    dynamicHeight,
    tillData,
    displayClock,
    setDisplayClock,
    confirmDiscardCart,
    cart,
    setCart,
    deleteCart,
    showOrderHistory,
    currencyType,
    openPaymentModal,
    setProductsList,
    productsList,
    getMoreProducts,
    productCategories,
    selectProductCategory,
    pickProduct,
    setDisplayCustomerSearch,
    parkBill,
    parkedList,
    openDisplayParkedBillModal,
    handleManagement,
    managementScreenShow,
    setManagementScreenShow,
    orderTypeSelection,
    setOrderTypeSelection,
    setManualDiscountInput,
    setManualDiscountModalVisible,
    manualDiscountModalVisible,
    manualDiscountTypes,
    setSelectedManualDiscountType,
    enableManualDiscountInput,
    manualDiscountInput,
    applyManualDiscount,
    removeAllDiscounts,
    handleManualDiscountKeyPress,
    loyalityOtpModalVisible,
    setLoyalityOtpModalVisible,
    setLoyaltyPaymentOtp,
    processOtpInput,
    loyaltyPaymentOtp,
    
  } = props;

  const history = useHistory();
  const tillaccess = JSON.parse(tillData.tillAccess.userAccessController);
  const currenciesList = tillData.tillAccess.csBunit.currencies;

  // SAVING BILL BLOCK START //
  const storedLocatedList = JSON.parse(localStorage.getItem("locatedList"));
  const initialLocatedList = storedLocatedList ? storedLocatedList : [];
  const [locatedList, setLocatedList] = useState(initialLocatedList);
  const [selectedTable, setSelectedTable] = useState(JSON.parse(localStorage.getItem("tableName")) !== "null" ?  JSON.parse(localStorage.getItem("tableName")) : history?.location?.state || "");
  const [orderedData,setOederedData] = useState([]);
  const [fbOrderId,setFbOrderId] = useState(uuidv4().replace(/-/g, "").toUpperCase());
  const inputRef = useRef(null);

  useEffect(() => {
    const nWStatus = navigator.onLine ? "online" : "offline";
    if(nWStatus === "online" ){
      if(selectedTable.data !== undefined && selectedTable?.data?.lines !== undefined){
        let tempCart = {};
        let newArray = [];
        let sOrderID = null;
        selectedTable?.data?.lines?.map((item,index) => {
          let obj = JSON.parse(item.meta[0].value);
          if(index === selectedTable?.data?.lines.length-1){
            sOrderID = obj.sOrderID;
            tempCart.tax = obj.tax;
            tempCart.total = obj.total;
            tempCart.totalQty = obj.totalQty;
            tempCart.customer = obj.customer;
          }
          delete obj.tax;
          delete obj.total;
          delete obj.totalQty;
          delete obj.customer;
          delete obj.sOrderID;
          obj.tax = item.csTaxId;
          obj.order = item.isOrdered;
          newArray.push(obj);
        });
        if(tempCart.customer === undefined)tempCart.customer = cart.customer;
         let cartObj =  {
          ...tempCart,
          "items": [...newArray],
          "discount": 0,
          "paid": 0,
          "change": 0,
          "roundOff": 0,
          "payments": [],
          "redemptionPoints": 0,
          "accumulationPoints": 0,
          "creditAmount": 0,
          "sOrderID": sOrderID,
          "salesRepId": selectedTable?.data?.salesRepId,
          "tableId": selectedTable?.data?.fbTableId,
          "tableNo": selectedTable?.data?.fbTableId,
          "fbOrderId": selectedTable?.data?.fbOrderId,
        };
        setCart(cartObj);
      }else{
        const tokens = JSON.parse(localStorage.getItem("tokens"));
        const paramsInput = {
          query: `mutation {
            upsertFBOrder(order: {
              cSBunitID: "${tillData.tillAccess.csBunit.csBunitId}"
              fbOrderId: "${fbOrderId}"
              fbTableId: "${selectedTable?.cwrFbTableId}"
              fbSectionId: "${selectedTable?.cwrFbsectionId}"
              sOrderId: null
              orderNo: "${localStorage.getItem("orderId") === null ? 1 : localStorage.getItem("orderId")}"
              guestName: "${selectedTable?.guestName}"
              guestType: "${selectedTable?.guestType}"
              referredBy: "${selectedTable?.referredBy}"
              date: "${moment(new Date()).format("YYYY-MM-DD")}"
              status: "IP"
              salesRepId: "${tillData.tillAccess.salesRepId}"
              guests: ${selectedTable?.noOfPersons}
              lines: []
             }) { 
          status
          message   
          }
          }`,
        };
        Axios({
          url: serverUrl,
          method: "POST",
          data: paramsInput,
          headers: {
            "Content-Type": "Application/json",
            Authorization: `${tokens.token_type} ${tokens.access_token}`,
          },
        }).then((response) => {
          if(response !== null){
            if(response?.data?.data?.upsertFBOrder.status === "200"){
              let newLocated = [...locatedList];
              if(localStorage.getItem("customerName") !== "undefined"){
              cart.customer = JSON.parse(localStorage.getItem("customerName"));
              }
              let newCart = cart;
              newCart.fbOrderId = fbOrderId;
              newLocated = [
                ...newLocated,
                {
                  name: selectedTable?.table,
                  cart: newCart,
                  id: selectedTable?.id,
                  noOfPersons: selectedTable?.noOfPersons,
                  dineIn:localStorage.getItem("dineIn"),
                },
              ];
              localStorage.setItem("locatedList", JSON.stringify(newLocated));
              setLocatedList([...newLocated]);
              setCart(newCart);
            }
          }
        });
      }
    }else{
      let newLocated = [...locatedList];
      let newCart = cart;
      newCart?.items?.map((cartItem, index) => (cartItem.parkedItem = true));
      const index = locatedList?.findIndex((item) => item.id === selectedTable?.id);
      if (index !== -1){
        newLocated[index] = {
          ...newLocated[index],
          cart: newCart,
        };
      } else {
        newLocated = [
          ...newLocated,
          {
            name: selectedTable?.table,
            cart: newCart,
            id: selectedTable?.id,
            noOfPersons: selectedTable?.noOfPersons,
            dineIn:localStorage.getItem("dineIn"),
          },
        ];
      }
      localStorage.setItem("locatedList", JSON.stringify(newLocated));
      setLocatedList([...newLocated]);
      setCart(newCart)
    }
}, []);

useEffect(()=>{
  if(selectedTable.data !== undefined && selectedTable?.data?.lines !== undefined){
  }else{
    if(localStorage.getItem("customerName") !== "undefined"){
      cart.customer = JSON.parse(localStorage.getItem("customerName"));
      }
      setCart(cart);
  }
},[cart]);

  // SAVING BILL BLOCK END //

  // CLOCK BLOCK START //
  useEffect(() => {
    const timerId = setInterval(() => setDisplayClock(clock()), 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);
  // CLOCK BLOCK END

  // Add ONS Modal Starts //

  const [displayAddOnSelection, setDisplayAddOnSelection] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [addOnsList, setAddOnsList] = useState({
    requiredList: [],
    optionsList: [],
  });
  const [selectedAddons, setSelectedAddons] = useState([]);

  const handleAddOnModal = (record, event) => {
    event.stopPropagation();
    setDisplayAddOnSelection(true);
    setSelectedProduct(record);
    const requiredListFilter = _.filter([...record.productAddons], (item) => item.mAddonGroup.minqty === 1);
    const optionsListFilter = _.filter([...record.productAddons], (item) => item.mAddonGroup.minqty === 0);
    setAddOnsList({
      requiredList: requiredListFilter || [],
      optionsList: optionsListFilter || [],
    });
  };

  const handleAddOnValue = (e, fieldName) => {
    let newSelectedAddons = [...selectedAddons];
    const indexValue =
      fieldName === "addOnRadio"
        ? _.findIndex(newSelectedAddons, (item) => item.mAddonGroup.mAddonGroupId === e.target.value.mAddonGroup.mAddonGroupId)
        : _.findIndex(newSelectedAddons, (item) => item === e.target.value);
    if (indexValue !== -1) {
      if (e.target.checked) {
        newSelectedAddons[indexValue] = e.target.value;
      } else {
        newSelectedAddons.splice(indexValue, 1);
      }
    } else {
      newSelectedAddons = [...selectedAddons, e.target.value];
    }
    setSelectedAddons(newSelectedAddons);
  };

  const handleQty = (fieldName) => {
    let newSelectedProduct = {
      ...selectedProduct,
      weight: selectedProduct?.weight || 1,
    };
    if (fieldName === "plus") {
      newSelectedProduct.weight = Number(newSelectedProduct.weight) + 1;
    }
    if (fieldName === "minus") {
      newSelectedProduct.weight = Number(newSelectedProduct.weight) - 1;
      if (newSelectedProduct.weight <= 1) {
        newSelectedProduct.weight = 1;
      }
    }

    setSelectedProduct({
      ...newSelectedProduct,
    });
  };

  const handleAdd = async () => {
    await addDefinedProductWithAddons(
      {
        ...selectedProduct,
        sProductID: uuidv4().replace(/-/g, "").toUpperCase(),
        productAddons: selectedAddons || [],
      },
      selectedProduct.upc,
      null,
      null,
      selectedProduct.sunitprice
    );
    handleAddOnModalClose();
  };

  const handleAddOnModalClose = () => {
    setDisplayAddOnSelection(false);
    setSelectedAddons([]);
  };

  // Add ONS Modal Ends //

  // Cart Functionality Starts //

  const [selectedProductInCart, setSelectedProductInCart] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const selectProductInCart = (record) => {
    console.log("newLocated record", record);
    setSelectedRowKeys([record.key]);
    setSelectedProductInCart(record);
  };

  const deleteProduct = (addToCart) => {
    if (!addToCart.isReturn) {
      addProductWithAddons(addToCart, -addToCart.weight);
    }
  };

  const decreaseProductQty = (addToCart) => {
    if (!addToCart.isManualQty) {
      addProductWithAddons(addToCart, -1);
    }
  };

  const increaseProductQty = (addToCart) => {
    if (!addToCart.isManualQty) {
      // addProductWithAddons(addToCart, 1);
    }
  };

  const addDefinedProductWithAddons = (productObjs, upc, batchno, mBatchId, price) => {
    const productObj = { ...productObjs };
    if (productObj.overRideTax === "Y" && price <= productObj.overRideCondition) {
      // prettier-ignore
      const originalPrice = price - (price - (price * (100 / (100 + productObj.taxRate))));
      const taxedPrice = originalPrice + (originalPrice * productObj.contraRate) / 100;
      price = taxedPrice;
      productObj.cTaxId = productObj.contraTaxId;
      productObj.taxRate = productObj.contraRate;
    }
    const productDefined = {
      batchno: batchno,
      description: productObj.description,
      discount: 0,
      discountName: "",
      imageurl: productObj.imageurl,
      isDecimal: productObj.isDecimal,
      isManualQty: productObj.isManualQty,
      isPromoApplicable: productObj.isPromoApplicable,
      isReturn: false,
      mBatchId: mBatchId,
      mProductId: productObj.mProductId,
      mPricingruleId: null,
      name: productObj.name,
      nettotal: 0,
      primaryOrderLine: null,
      productId: productObj.mProductId,
      realPrice: price,
      returnQty: null,
      salePrice: price,
      stock: productObj.onhandQty,
      tax: productObj.cTaxId,
      taxAmount: 0,
      taxRate: productObj.taxRate,
      uom: productObj.csUomId,
      uom_name: productObj.uomName,
      upc: upc,
      value: productObj.value,
      order: "N",
      productionCenter:productObj.productionCenter,
      // weight: 0,
      weight: productObj.weight || 1,
      shortDescription: productObj.shortDescription,
      hsncode: productObj.hsncode,
      csBunitId: productObj.csBunitId,
      mProductCategoryId: productObj.mProductCategoryId,
      productManufacturerId: productObj.productManufacturerId,
      productBrandId: productObj.productBrandId,
      productCategoryName: productObj?.productCategoryName || "",
      productAddons: productObj?.productAddons || [],
      sProductID: productObj?.sProductID,
      batchedProduct: productObj.batchedProduct,
      batchedForSale: productObj.batchedForSale,
      batchedForStock: productObj.batchedForStock,
      multiPrice: productObj.multiPrice,
      shelfLife: productObj.shelfLife,
    };
    addProductWithAddons(productDefined, productDefined.weight);
  };

  const addProductWithAddons = (addToCart, qty) => {
    console.log("prod", addToCart, qty);
    const weight = parseFloat(qty);
    const addedToCart = cart.items;
    const index = addedToCart.findIndex(
      (p) => p.productId === addToCart.productId && p.order === "N"
      // &&
      // p.upc === addToCart.upc &&
      // p.mBatchId === addToCart.mBatchId
    );
    
    console.log("newLocated index", index,addedToCart[index]);
    // console.log("newLocated addToCart", addToCart, addedToCart);
    if (index >= 0) {
      if (weight > 0 ) {
        message.success(`${addedToCart[index].name} Added Successfully`);
      }
      addedToCart[index].weight = addedToCart[index].weight + weight;
      if (addedToCart[index].weight === 0) {
        addedToCart.splice(index, 1);
      } else if (addedToCart[index].weight < 0) { // check if the resulting weight is negative
        message.error(`Invalid weight for ${addedToCart[index].name}`);
        addedToCart[index].weight = addedToCart[index].weight - weight; // undo the weight update
      } else {
        // calculate tax and net total
        const addOnsPriceSum = _.sumBy(addToCart?.productAddons, "price");
        const mrp =
          addToCart.productAddons?.length > 0
            ? addOnsPriceSum + parseFloat(addedToCart[index].salePrice) * addedToCart[index].weight
            : parseFloat(addedToCart[index].salePrice) * addedToCart[index].weight;
        const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
        addedToCart[index].taxAmount = tax;
        addedToCart[index].nettotal = mrp;
        const presentProduct = addedToCart[index];
        addedToCart.splice(index, 1);
        addedToCart.unshift(presentProduct);
        addedToCart.reverse();
      }
    } else {
      if (weight !== 0) {
        addToCart.weight = weight;
        if (addToCart.weight < 0) { // check if the weight is negative
          addToCart.weight = Math.abs(weight);
        } else {
          const addOnsPriceSum = _.sumBy(addToCart?.productAddons, "price");
          const mrp =
            addToCart.productAddons?.length > 0 ? (addOnsPriceSum + parseFloat(addToCart.salePrice)) * addToCart.weight : parseFloat(addToCart.salePrice) * addToCart.weight;
          const tax = mrp - mrp / (1 + addToCart.taxRate / 100);
          addToCart.taxAmount = tax;
          addToCart.nettotal = mrp;
          addedToCart.push(addToCart);
        }
      } else {
        addedToCart[index].weight = addedToCart[index].weight + weight;
        if (addedToCart[index].weight === 0) {
          addedToCart.splice(index, 1);
        } else if (addedToCart[index].weight < 0) { // check if the resulting weight is negative
          addedToCart[index].weight = addedToCart[index].weight - weight; // undo the weight update
        }
      }
    }
    
    let totalTax = 0;
    let totalPrice = 0;
    let totalItemsQty = 0;
    let totalDiscounts = 0;
    for (let i = 0; i < addedToCart.length; i += 1) {
      totalPrice += addedToCart[i].nettotal;
      totalItemsQty += addedToCart[i].weight;
      totalTax += addedToCart[i].taxAmount;
      totalDiscounts += addedToCart[i].discount;
      addedToCart[i].key = i;
    }

    addedToCart.reverse();

    const roundOffValue = Math.round(totalPrice);
    const totalRoundOff = totalPrice - roundOffValue;
    totalPrice = roundOffValue;
    setCart({
      ...cart,
      items: [...addedToCart],
      total: totalPrice,
      tax: totalTax,
      discount: totalDiscounts,
      totalQty: totalItemsQty,
      roundOff: totalRoundOff,
      tableId: selectedTable?.id,
    });
    setSelectedRowKeys([]);
    setSelectedProductInCart({});
    localStorage.setItem("locatedList", JSON.stringify(locatedList));

    // checkProductDiscounts(addToCart);
  };

  // Cart Functionality Ends //

  // Table Management Starts //

  const handleDinein = () => {
    history.push("/table-management");
  };

  const handleCancelTable = () => {
    const tokens = JSON.parse(localStorage.getItem("tokens"));
    const updateStatus = {
      query: `mutation {
        upsertTableStatus(tableStatus: {
           cSBunitID: "${tillData.tillAccess.csBunit.csBunitId}"
          fbtableStatusId: "${selectedTable.fbtableStatusId}"
          fbTableId: "${selectedTable.cwrFbTableId}"
          fbSectionId: "${selectedTable.cwrFbsectionId}"
          status: "OPN"
          guests: ${selectedTable.noOfPersons}
          guestName: ${selectedTable.name === undefined ? null : `"${selectedTable.name}"`}
          guestType: "${selectedTable.guestType}"
          referredBy: ${selectedTable.referredBy === undefined ? null : `"${selectedTable.referredBy}"`}
          salesRepId: "${tillData.tillAccess.salesRepId}"
         }) { 
      status
      message   
      }
      }`,
    };
    Axios({
      url: serverUrl,
      method: "POST",
      data: updateStatus,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    }).then((response) => {
      if(response.data.data.upsertTableStatus.status === "200"){
        const paramsInput = {
          query: `mutation {
            upsertFBOrder(order: {
              cSBunitID: "${tillData.tillAccess.csBunit.csBunitId}"
              fbOrderId: "${cart.fbOrderId}"
              fbTableId: "${selectedTable?.cwrFbTableId}"
              fbSectionId: "${selectedTable?.cwrFbsectionId}"
              sOrderId: null
              orderNo: "${localStorage.getItem("orderId")}"
              guestName: "${selectedTable?.guestName}"
              guestType: "${selectedTable?.guestType}"
              referredBy: "${selectedTable?.referredBy}"
              date: "${moment(new Date()).format("YYYY-MM-DD")}"
              status: "CL"
              salesRepId: "${tillData.tillAccess.salesRepId}"
              guests: ${selectedTable?.noOfPersons}
              lines: []
             }) { 
          status
          message   
          }
          }`,
        };
        Axios({
          url: serverUrl,
          method: "POST",
          data: paramsInput,
          headers: {
            "Content-Type": "Application/json",
            Authorization: `${tokens.token_type} ${tokens.access_token}`,
          },
        }).then((response)=>{
          if(response?.data?.data?.upsertFBOrder.status === "200"){
            let newArray = [];
            locatedList.map((item)=>{
              if(item.id !== selectedTable?.cwrFbTableId){
                newArray.push(item);
              }
            });
            setLocatedList(newArray);
            localStorage.setItem("locatedList",JSON.stringify(newArray));
            history.push("/table-management");
          }
        })
      }
    });
  }

  const [selectedItemsModalOpen, setSelectedItemsModalOpen] = useState(false);

  const handleOrder = () => {
    let newLocated = [...locatedList];
    let newCart = cart;
      newCart?.items?.map((cartItem, index) => (cartItem.parkedItem = true));
    let newTempData = newCart?.items?.filter((cartItem, index) => (cartItem.order === "N"));
    const index = locatedList?.findIndex((item) => item.id === selectedTable?.id);
    let serviceData = {}
    if (index !== -1) {
      newLocated[index] = {
        ...newLocated[index],
        cart: newCart,
      };
      serviceData= newLocated[index];
    } else {
    // localStorage.setItem("orderId", orderId);
    let object = {
      name: selectedTable?.table,
      cart: newCart,
      id: selectedTable?.id,
      noOfPersons: selectedTable?.noOfPersons,
      dineIn:localStorage.getItem("dineIn"),
    }
      newLocated = [
        ...newLocated,
        object,
      ];
      serviceData= object;
    }
    localStorage.setItem("locatedList", JSON.stringify(newLocated));
    setLocatedList([...newLocated]);
    if(newCart.totalQty>0 && newTempData.length>0){
      setOederedData(newTempData);
      setSelectedItemsModalOpen(true);
    }
  };

  const handleDisplayOk = (orderFlags) => {
    let orderId = localStorage.getItem("orderId") === null ? 1 : Number(localStorage.getItem("orderId")) + 1;
    localStorage.setItem("orderId", orderId);
    setSelectedItemsModalOpen(false);
    let newCart = cart;
    if(orderedData.length>0){
      const tokens = JSON.parse(localStorage.getItem("tokens"));
      let orderIdNo = selectedTable?.data?.fbOrderId === undefined ? fbOrderId : selectedTable.data.fbOrderId;
      let orderLines = [];
      orderedData.map((item)=>{
        let fbOrderLineId = uuidv4().replace(/-/g, "").toUpperCase();
        let fbMetaOrderId = uuidv4().replace(/-/g, "").toUpperCase();
        let metaString = {...item}; 
        metaString.tax = newCart.tax;
        metaString.total = newCart.total;
        metaString.totalQty = newCart.totalQty;
        metaString.customer = newCart.customer;
        metaString.sOrderID = newCart.sOrderID;
        orderLines.push(`{
          fbOrderId: "${orderIdNo}"
          fbOrderLineId: "${fbOrderLineId}"
          mPoductId: "${item.productId}"
          mBatchId: null
          description: "good"
          csUomId: "${item.uom}"
          csTaxId: "${item.tax}"
          line: 1
          qty: ${item.weight}
          unitPrice: ${item.realPrice}
          listPrice: 30
          lineNet: 2.6
          lineTax: ${item.taxRate}
          lineGross: 30
          sOrderLineId: null
          isOrdered: "Y"
          meta:[{
            key:"orderLine"
            value:${ JSON.stringify(JSON.stringify(metaString))}
            fbOrderLineMetaId:"${fbMetaOrderId}"
          }]
          }`);
      })
      const paramsInput = {
        query: `mutation {
          upsertFBOrder(order: {
            cSBunitID: "${tillData.tillAccess.csBunit.csBunitId}"
            fbOrderId: "${orderIdNo}"
            fbTableId: "${selectedTable?.id}"
            fbSectionId: "${selectedTable.cwrFbsectionId}"
            sOrderId: null
            orderNo: "${orderId}"
            guestName: "${selectedTable.guestName === undefined ? "" : selectedTable.guestName}"
            guestType: "${selectedTable.guestType}"
            referredBy: "${selectedTable.referredBy === undefined ? "" : selectedTable.referredBy}"
            date: "${moment(new Date()).format("YYYY-MM-DD")}"
            status: "IP"
            salesRepId: "${selectedTable.waiter}"
            guests: ${selectedTable.noOfPersons}
            lines: [${orderLines}]
           }) { 
        status
        message   
        }
        }`,
      };
      Axios({
        url: serverUrl,
        method: "POST",
        data: paramsInput,
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${tokens.token_type} ${tokens.access_token}`,
        },
      }).then((response) => {
        if(response.data.data.upsertFBOrder.status === "200"){
        }
      });
    }
    locatedList.map((item)=>{
      if(item.id === selectedTable?.id){
      const printKOTArray = item?.cart.items.reduce((result, current) => {
        const category = current.productionCenter;
        if (!result[category]) {
          result[category] = [];
        }
        result[category].push(current);
        return result;
      }, {});
    setTimeout(()=>{
    kotPrinter(printKOTArray,item?.cart,"receipt");
    localStorage.setItem("locatedList",JSON.stringify(locatedList));
    history.push("/table-management");
    },500)
      }
    })
  };

  // Kitchen Display Modal Starts //

  const orderStatus = [
    {
      title: "All",
      imgSrc: All,
      statusValue: "NE",
    },
    {
      title: "Parked",
      imgSrc: ParkedOrder,
      statusValue: "NE",
    },
    {
      title: "Pending",
      imgSrc: Pending,
      statusValue: "NE",
    },
    {
      title: "In Progress",
      imgSrc: InProgress,
      statusValue: "UP",
    },
    {
      title: "Prepared",
      imgSrc: Prepared,
      statusValue: "PR",
    },
    {
      title: "Completed",
      imgSrc: Completed,
      statusValue: "DE",
    },
  ];

  const [displayOrderItemsModal, setDisplayOrderItemsModal] = useState(false);
  const [selectedOrderStatus, setSelectedOrderStatus] = useState(orderStatus[0]);

  const orderDetailsList = JSON.parse(localStorage.getItem("orderDetails"));
  const initialOrderList = orderDetailsList ? orderDetailsList : [];
  const [orderDetails, setOrderDetails] = useState(initialOrderList);

  const handleOrderStatus = () => {
    const tokens = JSON.parse(localStorage.getItem("tokens"));
    db.orders
      .orderBy("orderTime")
      .limit(20)
      .reverse()
      .toArray()
      .then((data) => {
        if (data?.length > 0) {
          data?.map((item) => {
            let time = new Date(item.orderTime);
            let newTime = time.toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            });
            let newLineItems = item?.items?.map((itemLine) => ({
              ...itemLine,
              price: itemLine?.salesPrice || 0,
              quantity: itemLine?.weight || 0,
            }));
            let orderObj = {
              cWCOrderId: item.sOrderID || "",
              customerId: item?.customer?.cwrCustomerId || "",
              customerName: item?.customer?.name || "",
              lineItems: [...newLineItems],
              mobileNo: item?.customer?.mobileNo,
              noOfItems: item?.items?.length,
              orderNo: item.documentno,
              posOrders: "Y",
              status: "NE",
              total: item?.total || 0,
              totalQty: item?.totalQty || 0,
              dateCreated: newTime,
            };
            let findIndexOrder = _.findIndex(orderDetails, ["cWCOrderId", orderObj.cWCOrderId]);
            if (findIndexOrder === -1) {
              orderDetails.push(orderObj);
            } else {
              orderDetails[findIndexOrder] = orderObj;
            }
            return null;
          });
        }
      });
    const paramsInput = {
      query: `query{
        getNewOmsOrders(bunitId:"${tillData.tillAccess.csBunit.csBunitId}"){
        cWCOrderId
        orderNo
        dateCreated
        customerId
        customerName
        mobileNo
        total
        noOfItems
        totalQty
        lineItems{
            productId
            name
            productCode
            quantity
            price
            subTotal
            addOnProducts{
                id
                name
                price
            }
        }
    }
    }`,
    };
    Axios({
      url: serverUrl,
      method: "POST",
      data: paramsInput,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    }).then((response) => {
      const { getNewOmsOrders } = response.data.data;
      getNewOmsOrders?.map((item, index) => {
        let obj = { ...item };
        obj.status = "NE";
        obj.noOfItems = item.lineItems.length;
        obj.posOrders = "N";
        let time = new Date(item.dateCreated);
        let newTime = time.toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });
        obj.dateCreated = newTime;
        let findIndex = _.findIndex(orderDetails, ["cWCOrderId", item.cWCOrderId]);
        if (findIndex === -1) {
          orderDetails.push(obj);
        } else {
          orderDetails[findIndex] = obj;
        }
        return null;
      });
      localStorage.setItem("orderDetails", JSON.stringify([...orderDetails]));
      setOrderDetails([...orderDetails]);
    });
    setDisplayOrderItemsModal(true);
  };

  const handleOrderStatusSelection = (record) => {
    setSelectedOrderStatus(record);
  };

  const nextOrderStatus = () => {
    let newOrderStatus = [...orderStatus];
    let statusIndex = _.findIndex(newOrderStatus, (item) => item.title === selectedOrderStatus.title);
    if (statusIndex <= newOrderStatus.length - 1) setSelectedOrderStatus(newOrderStatus[statusIndex + 1]);
  };

  const setStatus = (status) => {
    let newStatus = "";
    switch (status) {
      case "NE":
        newStatus = "UP";
        break;
      case "UP":
        newStatus = "PR";
        break;
      case "PR":
        newStatus = "DE";
        break;
      default:
        newStatus = "NE";
    }
    return newStatus;
  };

  const handleStatusButton = (record) => {
    if ((record.status === "NE" || record.status === "UP" || record.status === "PR") && record.posOrders === "N") {
      handleInProgress(record);
    } else {
      let newOrderDetails = [...orderDetails];
      let recordIndex = _.findIndex(newOrderDetails, ["cWCOrderId", record.cWCOrderId]);
      newOrderDetails[recordIndex].status = setStatus(record.status);
      localStorage.setItem("orderDetails", JSON.stringify(orderDetails));
      setOrderDetails([...newOrderDetails]);
      nextOrderStatus();
    }
  };

  const handleInProgress = (record) => {
    const tokens = JSON.parse(localStorage.getItem("tokens"));
    const paramsInput = {
      query: `mutation{
        updateOMSOrderStatus(order:{
            cWCOrderId: "${record.cWCOrderId}"
            status: "${record.status}"
        })
        {
            status
            message
        }
    }`,
    };
    Axios({
      url: serverUrl,
      method: "POST",
      data: paramsInput,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    }).then((response) => {
      if (response?.data?.data?.updateOMSOrderStatus?.status === "200") {
        let newOrderDetails = [...orderDetails];
        let recordIndex = _.findIndex(newOrderDetails, ["cWCOrderId", record.cWCOrderId]);
        newOrderDetails[recordIndex].status = setStatus(record.status);
        localStorage.setItem("orderDetails", JSON.stringify(orderDetails));
        setOrderDetails([...newOrderDetails]);
        nextOrderStatus();
      }
    });
  };

  // Kitchen Display Modal Ends //

  // BARCODE READER BLOCK START //

  const [barcodeOrderList, setBarcodeOrderList] = useState([]);
  const [barcodeOrderStatusModal, setBarcodeOrderStatusModal] = useState(false);

  const barcodeOrderInput = (data) => {
    let str = _.startsWith(data, "m", 0);
    if (str === true) {
      const tokens = JSON.parse(localStorage.getItem("tokens"));
      let newId = data.slice(7);
      const paramsInput = {
        query: `query{
          verifyOMSOrder(omsOrderId:"${newId}"){
          cWCOrderId
          orderNo
          dateCreated
          customerId
          customerName
          mobileNo
          total
          noOfItems
          totalQty
          lineItems{
              productId
              name
              productCode
              quantity
              price
              subTotal
              addOnProducts{
                  id
                  name
                  price
              }
          }
      }
      }`,
      };
      Axios({
        url: serverUrl,
        method: "POST",
        data: paramsInput,
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${tokens.token_type} ${tokens.access_token}`,
        },
      }).then((response) => {
        const { verifyOMSOrder } = response.data.data;
        if (verifyOMSOrder) {
          let newBarcodeOrderList = [];
          let orderDate = "";
          verifyOMSOrder?.map((item, index) => {
            let obj = {
              ...item,
            };
            obj.status = "NE";
            obj.noOfItems = item.lineItems.length;
            obj.posOrders = "N";
            let time = new Date(item.dateCreated);
            let newTime = time.toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            });
            obj.dateCreated = newTime;
            orderDate = time.toLocaleDateString();
            newBarcodeOrderList.push(obj);
            return null;
          });
          setBarcodeOrderList([...newBarcodeOrderList]);
          if (tillaccess.kds_print === "Y") {
            handleBarcodeOrderPrint({
              ...newBarcodeOrderList[0],
              dateCreated: orderDate,
            });
            message.success(`Order ${newBarcodeOrderList?.[0]?.orderNo} Printed Successfully`);
          } else {
            setBarcodeOrderStatusModal(true);
          }
        }
      });
    }
  };

  const handleAccept = (record) => {
    const tokens = JSON.parse(localStorage.getItem("tokens"));
    const paramsInput = {
      query: `mutation{
        updateOMSOrderStatus(order:{
            cWCOrderId: "${record.cWCOrderId}"
            status: "DE"
        })
        {
            status
            message
        }
    }`,
    };
    Axios({
      url: serverUrl,
      method: "POST",
      data: paramsInput,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    }).then((response) => {
      if (response?.data?.data?.updateOMSOrderStatus?.status === "200") {
        message.success(`Order ${barcodeOrderList[0]?.orderNo} Completed Successfully`);
        setBarcodeOrderStatusModal(false);
      }
    });
  };

  // BARCODE READER BLOCK START //

  // BARCODE ORDER PRINT START //
  const handleBarcodeOrderPrint = (printedData) => {
    const printWindow = window.open("", "printWindow", "width=0,height=0");
    let printedDataArray = printedData?.lineItems?.map((item) => ({
      ...item,
      productName: item?.name,
      price: item?.price,
      qty: item?.quantity,
    }));
    const barcodeOrderData = `
      <html>
      <style>
      th,td {
        padding: 15px;
      }
      </style>
        <div style="width:100%;">
        <div style="text-align:center; margin-bottom: 20px;"><h4>${tillData.tillAccess.csBunit.name}</h4></div>
        <div style="display:flex; margin-bottom: 20px;">
        <span>Order No</span>
          <span style="margin-left: 100px;">${printedData.orderNo}</span>
        </div>
        <div style="display:flex; margin-bottom: 20px;">
        <span>Date</span>
          <span style="margin-left: 100px;">${printedData.dateCreated}</span>
        </div>
        <hr />
        <table style="width:100%">
              <tr>
                <th style="text-align:left;"><strong>Product</strong></th>
                <th style="text-align:left;"><strong>Qty</strong></th>
                <th style="text-align:left;"><strong>Price</strong></th>
              </tr>
              ${printedDataArray?.map((item) => {
                return `<tr>
                  <td>${item.productName}</td>
                  <td>${item.qty}</td>
                  <td>${item.price}</td>
                </tr>
                ${item?.addOnProducts?.map((addonItem) => {
                  return `<tr>
                    <td>--${addonItem.name}</td>
                    </tr>`;
                })}
               `;
              })}
          </table>
          <div style="display:flex; margin-top: 30px;">
          <h2>Total</h2>
            <h2 style="margin-left: 100px; text-align: end">${_.sumBy(printedDataArray, "price")}</h2>
          </div>
          <div style="text-align:center; margin-bottom: 20px;"><h4>Thank You</h4></div>
        </div>
      </html>`;
    printWindow.document.write(barcodeOrderData);
    printWindow.print();
    printWindow.close();
  };

  // BARCODE ORDER PRINT END //


  // Bill Confirmation Starts //

  const [billConfirmationModalOpen, setBillConfirmationModalOpen] = useState(false);

  // Bill Confiemation Ends //

  const handleOrderSelection = (fieldName) => {
    setOrderTypeSelection(fieldName);
  };

  // Notes Functionality //

  const [notesModalOpen, setNotesModalOpen] = useState(false);
  const [layout, setLayout] = useState("default");
  const [inputs, setInputs] = useState({});
  const [inputName, setInputName] = useState("");
  const [inputFieldName,setInputFieldName] = useState("")
  const [keyboardVisible, setKeyboardVisible] = useState(true);
  const keyboard = useRef(null);


  const handleNotesModal = (id) => {
    const selectedItem = cart.items.find(item => item.productId === id);
    let notes = selectedItem?.notes === undefined ? "" : selectedItem?.notes;
    setInputName(id);
    setNotesModalOpen(true);
    setTimeout(()=>{
    keyboard.current.setInput(notes);
    })
  };

  const handleShift = () => {
    const newLayoutName = layout === "default" ? "shift" : "default";
    setLayout(newLayoutName);
  };

  const handleKeyPress = (button) => {
    if (button === "{shift}" || button === "{lock}") {
      handleShift();
    }
  };

  const handleKeyboardInputsNotes = (inputs) => {
    console.log(inputs)
    setInputs({
      ...inputs,
      [inputName]: inputs.default,
    });
    cart.items.map((item)=>{
      console.log(item.productId,);
      console.log(inputName)
      if(item.productId === inputName){
        item.notes = inputs.default;
        console.log(item);
      }
    })
  };

  const handleNotesValue = (event) => {
    const inputVal = event.target.value;
    setInputs({
      ...inputs,
      [inputName]: inputVal,
    });
    keyboard.current.setInput(inputVal);
  };

  const getInputValue = inputName => {
    return inputs[inputName] || "";
  };

  const handleNotesSubmit = () => {
      setKeyboardVisible(false);
      setTimeout(() => {
      setKeyboardVisible(true);
      setNotesModalOpen(false);
    }, 0); 
  }

  const handleBillPrint = () => {
    cart["orderSelection"] = orderTypeSelection;
    htmlPrint(cart, "Receipt");
    setBillConfirmationModalOpen(false);
  };


  return (
    <div className="main" style={data.restaurantPos.main}>
      <BarCodeComponent onScan={barcodeOrderInput} />
      <Row
        style={{
          height: `${3.7 * dynamicHeight}vw`,
          padding: data.restaurantPos.header.padding,
        }}
      >
        <Col span={1}>
          <img src={MenuIcon} style={data.restaurantPos.header.menuIcon} alt="" />
        </Col>
        <Col span={5}>
          <label style={data.restaurantPos.header.posHeadLabel}>{tillData.tillAccess.csBunit.name}</label>
        </Col>
        <Col span={5}>
          <label style={data.restaurantPos.header.timeFmt}>{tillData.tillAccess.cwrTill.till}</label>
        </Col>
        <Col span={5}>
          {
            JSON.parse(tillData.tillAccess.userAccessController).dineIn === "Y" ? <label style={{alignSelf:"center",color:"#fff",fontWeight:"600",letterSpacing:"0.36px",fontSize:"1.1vw",cursor:"pointer"}} onClick={()=>history.push("/table-management")}>Table</label> : ""
          }
        </Col>

        <Col span={4} style={data.restaurantPos.header.right}>
          <label style={data.restaurantPos.header.time}>{displayClock}</label>
        </Col>
        <Col span={4} style={data.restaurantPos.header.right}>
          <img onClick={()=>history.push("/dashboard")} src={cwPosLogo} style={data.restaurantPos.header.posLogoIcon} alt="" />
        </Col>
      </Row>
      <Row style={data.restaurantPos.content.mainrowStyles} className="mainRow">
        <Col span={6} style={data.restaurantPos.content.orderCart.colStyles}>
          <div style={data.restaurantPos.content.orderCart.firstRow}>
            <Row gutter={[8, 8]}>
              <Col span={ tillData.tillAccess.salesRep === "N"? 12: 24}>
                <Button
                  style={localStorage.getItem("dineIn") === "Y" ? data.restaurantPos.content.orderCart.takeAwayBtn : data.restaurantPos.content.orderCart.dineBtn}
                  className={localStorage.getItem("dineIn") === "Y" ? "takeawayBtn" : "dineBtn"}
                  // onClick={() => (tillaccess.dineIn === "Y" ? handleDinein() : "")}
                  onClick={() => handleOrderSelection("Dine In")}
                >
                  {/* <img src={Dinner} alt="" style={{ marginTop: "-2px" }} />{" "} */}
                  <span style={localStorage.getItem("dineIn") === "Y" ? data.restaurantPos.content.orderCart.takeAwayBtnText : data.restaurantPos.content.orderCart.dineBtnText}>
                    Dine-in
                  </span>
                </Button>
              </Col>
              <Col span={12}>
                {tillData.tillAccess.salesRep === "N"? <Button
                  style={localStorage.getItem("dineIn") === "N" ? data.restaurantPos.content.orderCart.takeAwayBtn : data.restaurantPos.content.orderCart.dineBtn}
                  className={localStorage.getItem("dineIn") === "N" ? "takeawayBtn" : "dineBtn"}
                  onClick={() => handleOrderSelection("Take Away")}
                >
                  {/* <img src={Bag} alt="" style={{ marginTop: "-2px" }} />{" "} */}
                  <span style={localStorage.getItem("dineIn") === "N" ? data.restaurantPos.content.orderCart.takeAwayBtnText : data.restaurantPos.content.orderCart.dineBtnText}>
                    Take Away
                  </span>
                </Button>: ""}
              </Col>
            </Row>
            <Row style={{ marginTop: 5 }}>
              <Col span={tillaccess.dineIn === "Y" ? 12 : 24} style={{ cursor: "pointer", marginTop: 5 }}>
                <div onClick={() => setDisplayCustomerSearch(true)}>
                  <img className="addCustIcon" src={AddCust} alt="" />{" "}
                  <span className="addCustomerTextRest">
                    {cart.customer.name === "Anonymous " || cart.customer.name === "Anonymous Customer" || cart.customer.name === "Default F&B Customer"
                      ? "Add Customer"
                      : cart.customer.name}
                  </span>
                </div>
              </Col>
              <Col span={tillaccess.dineIn === "Y" ? 12 : 0} style={{ marginTop: 5, cursor: "none" }}>
                <Button size="small" style={data.restaurantPos.content.orderCart.noTableBtn}>
                  <img src={EmptyChair} alt="" style={{ marginTop: "-2px" }} />{" "}
                  <span style={data.restaurantPos.content.orderCart.noTableBtnText}>{selectedTable?.table || "No Table"}</span>
                </Button>
              </Col>
            </Row>
          </div>
          <Scrollbars autoHide className="productCartScroll">
            {Object.entries(_.groupBy(cart?.items || [], "productCategoryName")).map(([key, value]) => (
              <div style={data.restaurantPos.content.orderCart.thirdRow}>
                <Row>
                  <p style={data.restaurantPos.content.orderCart.productCategory}>{key}</p>
                </Row>
                {value?.map((item, index) => (
                  <div
                    style={{
                      backgroundColor: item?.parkedItem ? "#e6f7ff" : _.intersectionWith([item], [selectedProductInCart], _.isEqual)?.length > 0 ? "#f1f1f1" : "",
                      padding: "5px",
                      // cursor: item?.parkedItem ? "" : "pointer",
                    }}
                    // onClick={() => !item?.parkedItem && selectProductInCart(item)}
                  >
                    <Row>
                      <Col span={14}>
                        <p style={data.restaurantPos.content.orderCart.productDetails}>{item.name}</p>
                      </Col>
                      <Col span={10} style={data.restaurantPos.content.orderCart.amtTextAlign}>
                        <p style={data.restaurantPos.content.orderCart.productDetails}>
                          <Row>
                            <Col span={24}>
                              <span
                                style={data.restaurantPos.content.orderCart.productQtyBtns}
                                onClick={() => (parseFloat(item.weight) > 1 ? decreaseProductQty(item) : deleteProduct(item))}
                              >
                                <span style={data.restaurantPos.content.orderCart.plusMinusIcon}>-</span>
                              </span>
                              <span style={data.restaurantPos.content.orderCart.productQty}>
                                <span style={data.restaurantPos.content.orderCart.productDetails}>{item.weight}</span>
                              </span>
                              <span style={data.restaurantPos.content.orderCart.productQtyBtns} onClick={() => increaseProductQty(item)}>
                                <span style={data.restaurantPos.content.orderCart.plusMinusIcon}>+</span>
                              </span>
                            </Col> 
                          </Row>
                        </p>
                      </Col>
                    </Row>
                    {item?.productAddons?.map((subitem) => (
                      <div style={data.restaurantPos.content.orderCart.thirdRow}>
                        <Row>
                          <Col span={10} style={data.restaurantPos.content.orderCart.modifierCol}>
                            <p style={data.restaurantPos.content.orderCart.modifier}>{subitem.name}</p>
                          </Col>
                          <Col span={14}>
                            <p style={data.restaurantPos.content.orderCart.modifierPrice}>+ {subitem.price}</p>
                          </Col>
                        </Row>
                      </div>
                    ))}
                    <p style={data.restaurantPos.content.orderCart.productDetails}>
                      <Row>
                        <Col span={24} style={data.restaurantPos.content.orderCart.modifierCol}>
                          <p style={data.restaurantPos.content.orderCart.productDetails}>
                          {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""} {item.nettotal} {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                          </p>
                        </Col>
                      </Row>
                    </p>
                    <p style={data.restaurantPos.content.orderCart.productDetails}>
                      <Row>
                        <Col span={24} style={data.restaurantPos.content.orderCart.modifierCol}>
                          <p style={{
                            marginBottom: "5px",
                            fontSize: "12px",
                            fontFamily: "Poppins",
                            letterSpacing: "0px",
                            color: "#4C4C4C",
                            opacity: 1,
                            cursor: "pointer"
                          }} onClick={()=>{if(item.order === "N")handleNotesModal(item.productId)}}>
                            <img src={NotesLine} alt="" style={{ marginTop: "-3px" }} /> <span style={data.restaurantPos.content.orderCart.modifier}>{item?.notes}</span>
                          </p>
                        </Col>
                      </Row>
                    </p>
                  </div>
                ))}
              </div>
            ))}
          </Scrollbars>
          <Row>
            <div style={data.restaurantPos.content.updateOptions}>
              <Divider style={data.restaurantPos.content.orderCart.divider} />
              <Row style={data.restaurantPos.content.orderCart.totalRow}>
                <Col span={12}>
                  <label style={data.restaurantPos.content.orderCart.totalLabels}>No. of Items</label>
                </Col>
                <Col span={12} style={data.restaurantPos.content.orderCart.amtTextAlign}>
                  <label style={data.restaurantPos.content.orderCart.totalLabels}>{cart?.items.length || 0}</label>
                </Col>
              </Row>
              <Row style={data.restaurantPos.content.orderCart.totalRow}>
                <Col span={6}>
                  <label style={data.restaurantPos.content.orderCart.totalLabels}>Tax</label>
                </Col>
                <Col span={18} style={data.restaurantPos.content.orderCart.amtTextAlign}>
                  <label style={data.restaurantPos.content.orderCart.totalLabels}>
                  {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                    &nbsp;
                    {parseFloat(cart?.tax || 0).toFixed(2)} {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                  </label>
                </Col>
              </Row>
              <Row style={data.restaurantPos.content.orderCart.totalRow}>
                <Col span={6}>
                  <label style={data.restaurantPos.content.orderCart.totalLabels}>Discount</label>
                </Col>
                <Col span={18} style={data.restaurantPos.content.orderCart.amtTextAlign}>
                  <label style={data.restaurantPos.content.orderCart.totalLabels}>
                  {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                    &nbsp;
                    {parseFloat(cart?.discount || 0).toFixed(2)} {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                  </label>
                </Col>
              </Row>
              <Row style={data.restaurantPos.content.orderCart.totalRow}>
                <Col span={6}>
                  <label style={data.restaurantPos.content.orderCart.totalText}>Total</label>
                </Col>
                <Col span={18} style={data.restaurantPos.content.orderCart.amtTextAlign}>
                  <label style={data.restaurantPos.content.orderCart.totalText}>
                  {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                    &nbsp;
                    {parseFloat(cart?.total || 0).toFixed(2)} {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                  </label>
                </Col>
              </Row>
              {tillData.tillAccess.salesRep === "N" ? <Row gutter={[8, 8]} style={data.restaurantPos.content.orderCart.btnsRow}>
                <Col span={6}>
                  <Tooltip title="Order History">
                    <img src={InvoiceGreen} alt="invoice" className="optionsBtnsfb" onClick={() => showOrderHistory()} />
                  </Tooltip>
                </Col>
                {/* <Col span={6}>
                  <Tooltip title="Management Options">
                    <img src={SettingsGreen} alt="settings" className="optionsBtnsfb" onClick={() => handleManagement()} />
                  </Tooltip>
                </Col> */}
                <Col span={6}>
                  <Tooltip title="Apply Discount">
                    <img
                      src={OffersGreen}
                      alt=""
                      className="optionsBtnsfb"
                      onClick={() => {
                        setManualDiscountModalVisible(true);
                        setManualDiscountInput("");
                      }}
                    />
                  </Tooltip>
                </Col>
              </Row> : null}
              {tillData.tillAccess.salesRep === "Y"?(
                <Row gutter={[8, 0]} style={data.restaurantPos.content.orderCart.btnsRow}>
                  <Col span={12}>
                    <Button size="large" style={data.restaurantPos.content.orderCart.orderBtn} onClick={() => handleOrder()}>
                      <span style={data.restaurantPos.content.orderCart.checkoutBtn.label}>Order</span>
                    </Button>
                  </Col>
                  <Col span={12}>
                    {cart.items.length>0 ?<Button size="large" style={data.restaurantPos.content.orderCart.checkoutBtn} onClick={() => {if(tillData.tillAccess.salesRep === "N"){setBillConfirmationModalOpen(true)}else{handleBillPrint()}}}>
                      <span style={data.restaurantPos.content.orderCart.checkoutBtn.label}>Prepare Bill</span>
                    </Button> :
                    <Button size="large" style={data.restaurantPos.content.orderCart.checkoutBtn} onClick={handleCancelTable}>
                    <span style={data.restaurantPos.content.orderCart.checkoutBtn.label}>Cancel</span>
                    </Button>
                    }

                  </Col>
                </Row>
              ) : (
                <Row style={data.restaurantPos.content.orderCart.btnsRow} gutter={[8,0]}>
                  {localStorage.getItem("dineIn") === "Y" ? <>
                  {console.log(tillData.tillAccess.salesRep)}
                  <Col span={12}>
                    <Button size="large" style={data.restaurantPos.content.orderCart.checkoutBtn} onClick={() => handleBillPrint()}>
                      <span style={data.restaurantPos.content.orderCart.checkoutBtn.label}>Prepare Bill</span>
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button size="large" style={data.restaurantPos.content.orderCart.checkoutBtn} onClick={() => openPaymentModal()}>
                      <span style={data.restaurantPos.content.orderCart.checkoutBtn.label}>Checkout</span>
                    </Button>
                  </Col>
                  </> : 
                  <Col span={24}>
                  <Button size="large" style={data.restaurantPos.content.orderCart.checkoutBtn} onClick={() => openPaymentModal()}>
                    <span style={data.restaurantPos.content.orderCart.checkoutBtn.label}>Checkout</span>
                  </Button>
                </Col>
                  }
                </Row>
              )}
            </div>
          </Row>
        </Col>
        <Col
          span={18}
          style={{
            padding: "0.3%",
          }}
        >
          <div className="restaurantPosBgd" style={data.restaurantPos.content.productList.productListDiv}>
            <Scrollbars autoHide className="productScroll2">
              <InfiniteScroll
                dataLength={productsList.length}
                next={() => getMoreProducts()}
                hasMore={true}
                endMessage={
                  <p className="center">
                    <b>Yay! you have seen it all</b>
                  </p>
                }
                style={{ overflowX: "hidden" }}
              >
                <Row gutter={[4, 4]}>
                  {productsList.map((item, index) => {
                    const boxStyle = {
                      backgroundImage: `url(${item.imageurl})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      padding: 0,
                      height:"16vh"
                    };
                    return (
                      <Col span={6}>
                        <Card
                          bodyStyle={boxStyle}
                          style={data.restaurantPos.content.productList.productCard}
                          onClick={item?.productAddons?.length > 0 ? (e) => handleAddOnModal(item, e) : () => pickProduct(item)}
                        >
                          <div style={{marginTop:"10.6vh",backgroundColor:"rgb(254, 225, 201,0.8)",padding:"0px 5px 0px 5px"}}>
                          <p style={data.restaurantPos.content.productList.productName}>{item.name || "Empty Product"}</p>
                          <Row>
                            <Col span={12}>
                              <p style={data.restaurantPos.content.productList.productPrice}>{currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}&nbsp;&nbsp; {item.sunitprice} {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}</p>
                            </Col>
                            <Col span={12}>{item?.productAddons?.length > 0 ? <img src={AddCircle} alt="" style={data.restaurantPos.content.productList.addIcon} /> : ""}</Col>
                          </Row>
                          </div>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </InfiniteScroll>
            </Scrollbars>
            <Row style={data.restaurantPos.content.productList.productCategoryList}>
              <Scrollbars autoHide className="productCategoryScroll">
                <Row gutter={[4, 4]}>
                  {productCategories.length > 0 && (
                    <Col span={6}>
                      <Card
                        key={"itemAllProducts"}
                        bodyStyle={{ padding: 12, textAlign: "center" }}
                        style={data.restaurantPos.content.productList.productCategoryCard}
                        onClick={() => {
                          selectProductCategory("allProducts");
                        }}
                      >
                        <span style={data.restaurantPos.content.productList.productCategoryName}>All</span>
                      </Card>
                    </Col>
                  )}
                  {productCategories.map((item, index) => (
                    <Col span={6}>
                      <Card
                        bodyStyle={{ padding: 12, textAlign: "center" }}
                        style={data.restaurantPos.content.productList.productCategoryCard}
                        onClick={() => {
                          selectProductCategory(item.mProductCategoryId);
                        }}
                      >
                        <span style={data.restaurantPos.content.productList.productCategoryName}>{item.name}</span>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Scrollbars>
            </Row>
          </div>
        </Col>
      </Row>
      <RestaurantModals
        displayAddOnSelection={displayAddOnSelection}
        handleAddOnModalClose={handleAddOnModalClose}
        addOnsList={addOnsList}
        selectedProduct={selectedProduct}
        selectedAddons={selectedAddons}
        handleAddOnValue={handleAddOnValue}
        currencyType={currencyType}
        selectedRowKeys={selectedRowKeys}
        selectedProductInCart={selectedProductInCart}
        decreaseProductQty={decreaseProductQty}
        handleQty={handleQty}
        increaseProductQty={increaseProductQty}
        handleAdd={handleAdd}
        selectedItemsModalOpen={selectedItemsModalOpen}
        setSelectedItemsModalOpen={setSelectedItemsModalOpen}
        selectProductInCart={selectProductInCart}
        handleDisplayOk={handleDisplayOk}
        displayOrderItemsModal={displayOrderItemsModal}
        orderStatus={orderStatus}
        selectedOrderStatus={selectedOrderStatus}
        handleOrderStatusSelection={handleOrderStatusSelection}
        handleOrderStatus={handleOrderStatus}
        setDisplayOrderItemsModal={setDisplayOrderItemsModal}
        orderDetails={orderDetails}
        handleStatusButton={handleStatusButton}
        barcodeOrderStatusModal={barcodeOrderStatusModal}
        setBarcodeOrderStatusModal={setBarcodeOrderStatusModal}
        barcodeOrderList={barcodeOrderList}
        handleBarcodeOrderPrint={handleBarcodeOrderPrint}
        handleAccept={handleAccept}
        cart={cart}
        tillData={tillData}
        openPaymentModal={openPaymentModal}
        billConfirmationModalOpen={billConfirmationModalOpen}
        setBillConfirmationModalOpen={setBillConfirmationModalOpen}
        managementScreenShow={managementScreenShow}
        setManagementScreenShow={setManagementScreenShow}
        parkedList={parkedList}
        openDisplayParkedBillModal={openDisplayParkedBillModal}
        orderTypeSelection={orderTypeSelection}
        manualDiscountModalVisible={manualDiscountModalVisible}
        setManualDiscountInput={setManualDiscountInput}
        setManualDiscountModalVisible={setManualDiscountModalVisible}
        manualDiscountTypes={manualDiscountTypes}
        setSelectedManualDiscountType={setSelectedManualDiscountType}
        enableManualDiscountInput={enableManualDiscountInput}
        manualDiscountInput={manualDiscountInput}
        applyManualDiscount={applyManualDiscount}
        removeAllDiscounts={removeAllDiscounts}
        handleManualDiscountKeyPress={handleManualDiscountKeyPress}
        loyalityOtpModalVisible={loyalityOtpModalVisible}
        setLoyalityOtpModalVisible={setLoyalityOtpModalVisible}
        setLoyaltyPaymentOtp={setLoyaltyPaymentOtp}
        processOtpInput={processOtpInput}
        loyaltyPaymentOtp={loyaltyPaymentOtp}
        notesModalOpen={notesModalOpen}
        setNotesModalOpen={setNotesModalOpen}
      />

      {/* Notes Modal Starts */}
      <Modal visible={notesModalOpen} closable={false} footer={null} bodyStyle={{ padding: "15px" }}>
        <Row style={{ marginBottom: 10 }}>
          <Col span={16}>
            <p className="enter-receipt">Notes</p>
          </Col>
          <Col span={8} style={{ alignSelf: "center" }}>
            <CloseOutlined style={{ float: "right", fontSize: "16px", marginTop: "-4px" }} onClick={() => {    
            setKeyboardVisible(false);
            setTimeout(() => {
            setKeyboardVisible(true);
            setNotesModalOpen(false);
          }, 0);  
          }} />
          </Col>
        </Row>
        <TextArea
          rows={3}
          readOnly
          ref={inputRef}
          value={getInputValue(inputName)}
          onChange={handleNotesValue}
          placeholder="Type Here"
          className="refund-remarks"
        />
        {keyboardVisible && (<Keyboard
          keyboardRef={(r) => (keyboard.current = r)}
          layoutName={layout}
          onChangeAll={handleKeyboardInputsNotes}
          onKeyPress={handleKeyPress}
        />)}
        <Row style={{marginTop: 10, textAlign: "right"}}>
          <Col span={12} />
          <Col span={12}>
          <Button className="notes-cancelBtn" onClick={() => {    
            setKeyboardVisible(false);
            setTimeout(() => {
            setKeyboardVisible(true);
            setNotesModalOpen(false);
          }, 0);  
          }}>Cancel</Button> &nbsp; <Button className="notes-submitBtn" onClick={handleNotesSubmit}>Submit</Button>
          </Col>
        </Row>
      </Modal>
      {/* Notes Modal Ends */}
    </div>
  );
};
export default FBLayout;
