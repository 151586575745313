import React, { useState, useEffect,useRef } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, Button, Modal, Badge, Checkbox, Form, Input, Select, Radio ,Space, TimePicker ,DatePicker, Spin } from "antd";
import { LoadingOutlined, SearchOutlined, EditFilled } from "@ant-design/icons";
import { Scrollbars } from "react-custom-scrollbars";
import InfiniteScroll from "react-infinite-scroll-component";
import cwPosLogo from "../../../../assets/images/cwpos-green.png";
import MenuIcon from "../../../../assets/images/menu.png";
import Home from "../../../../assets/images/dashboard.png";
import TableReservedImg from "../../../../assets/images/table-reserved.svg";
import TableOccupiedImg from "../../../../assets/images/table-occupied.svg";
import TableOpenImg from "../../../../assets/images/table-open.svg";
import TableWaitingImg from "../../../../assets/images/table-waiting.svg";
import TableNoserviceImg from "../../../../assets/images/table-noservice.svg";
import data from "../../../../constants/restaurantPos.json";
import { clock, timeStamp, currentDay } from "../../../../utility/clock";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import "./style.css"
import Tick from "../../../../assets/images/tick-small.svg"
import { v4 as uuidv4 } from "uuid";
import db from "../../../../database";
import moment from "moment";
import { serverUrl } from "../../../../constants/config";
import Axios from "axios";
import { async } from "q";


const { Option } = Select;

const TableManagement = (props) => {
  const history = useHistory();
  const keyboard = useRef();
  const dynamicHeight = 0.5625;
  const [guestsModalFlag, setGuestsModalFlag] = useState(false);
  const [layout, setLayout] = useState("default");
  const [inputFields, setInputFields] = useState({});
  const [recordState,setRecardState] = useState("");
  const [inputName, setInputName] = useState("default");
  const [companyInputName, setCompanyInputName] = useState("default");
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const [tableCards,setTableCards] = useState([]);
  const [reserveFlag,setReserveFlag] = useState(false);
  const [selectData,setSelectData] = useState([]);
  const [mainTableData,setMainTableData] = useState([]);
  const [radioFlag,setRadioFlag] = useState(false);
  const [companyFlag,setCompanyFlag] = useState(false);
  const [radioValue,setRadioValue] = useState({value: "all",name:"All",});
  const [dineInFlag,setDineInFlag] = useState("Y");
  const [priorityBtn,setPriorityBtn] = useState(false);
  const [locatedList,setLocatedList] = useState(JSON.parse(localStorage.getItem("locatedList")) !== null ? JSON.parse(localStorage.getItem("locatedList")) : [] );
  const [orderedData,setOrdredData] = useState([]);
  const [keyboardVisible, setKeyboardVisible] = useState(true);
  const [tableList,setTableList] = useState([]);
  const [companySearchInput,setCompanySeacrhInput] = useState("");
  const keyboardRef = useRef(null);
  const [guestForm] = Form.useForm();
  const inputRef = useRef(null);
  const [loading, setLoading] = useState({ status: false, message: "" });
  const [displayCompanySearch,setDisplayCompanySearch] = useState(false)
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  const companySearchInputRef = useRef();
  const [customerSearchResults,setCustomerSearchResults] = useState([]);
  const [companyName,setCompanyName] = useState("");

  // CLOCK BLOCK START
  const [displayClock, setDisplayClock] = useState(clock());
  useEffect(async() => {
    localStorage.setItem("customerName",undefined);
    setLoading({ status: true, message: "" });
   let tempData = [];
   let data = [];
   await db.restaurantTables.toArray().then((fetched) => {
    data.push({value: "all",name:"All",});
    fetched.map((e) => {
      e.posTables.map(item=>{
        item.title = item.name;
        item.tableImg = <img src={TableReservedImg} alt="restaurant table" />;
        item.status = <Badge color="#a7c957"/>;
        item.merge = true;
        item.cwrFbsectionId = e.cwrFbsectionId;
        item.sectionName = e.sectionName
        item.table = item.name;
        item.id = item.cwrFbTableId;
        item.color = "#a7c957"
        item.cwrFbsectionId = e.cwrFbsectionId
        tempData.push(item);
      })
      data.push({value: e.cwrFbsectionId,name:e.sectionName,});
    });
    setReserveFlag(JSON.parse(tillData.tillAccess.userAccessController).Reservation)
    });
    let dineInKey = localStorage.getItem("dineIn") !== null ? localStorage.getItem("dineIn") : "Y";
    setDineInFlag(dineInKey);
    localStorage.setItem("dineIn",dineInKey);
    let occupiedData = [];
    let orderedArray = [];
    const tokens = JSON.parse(localStorage.getItem("tokens"));
    await Axios({
        url: serverUrl,
        method: "POST",
        data: {
          query: `query {
            getTableStatus(tableId: null) {
            cSBunitID
            cSClientID
            isactive
            fbTableId
            fbSectionId
            guestName
            guestType
            referredBy
            status
            salesRepId
            guests
            fbtableStatusId
            }
        }`,
        },
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${tokens.token_type} ${tokens.access_token}`,
        },
      }).then((response) => {
        if(response.data.data.getTableStatus.length>0){
          occupiedData = [...response.data.data.getTableStatus];
        }
      });
  locatedList?.findIndex((item) => {if(item.dineIn ==="N"){orderedArray.push(item)}});
  setOrdredData(orderedArray);
  setSelectData(data);
  tempData.map((e)=>{
    if(occupiedData.length>0){
      occupiedData?.map(ele=>{
        if(e.cwrFbTableId === ele.fbTableId){
          if(ele.status === "OCU"){
            e.status = <Badge color="#bc4749" />;
            e.color = "#bc4749";
            e.noOfPersons = ele.guests;
            e.guestName = ele.guestName;
            e.guestType = ele.guestType;
            e.referredBy = ele.referredBy;
            e.fbtableStatusId = ele.fbtableStatusId;
            e.statusType = "OCU"
          }else if(ele.status === "RES"){
            e.status = <Badge color="#f2e8cf" />;
            e.color = "#f2e8cf";
            e.noOfPersons = ele.guests;
            e.guestName = ele.guestName;
            e.guestType = ele.guestType;
            e.referredBy = ele.referredBy;
            e.fbtableStatusId = ele.fbtableStatusId;
            e.statusType = "RES"
          }else if(ele.status === "OPN"){
            e.status = <Badge color="#a7c957" />;
            e.color = "#a7c957";
            e.noOfPersons = "";
            e.guestName = "";
            e.guestType = "";
            e.referredBy = "";
            e.fbtableStatusId = ele.fbtableStatusId;
            e.statusType = "OPN"
          }
        }
      });
    };
  });
  setTableCards(tempData);
  setTableList(tempData);
  setMainTableData(tempData);
  setLoading({ status: false, message: "" });
    const timerId = setInterval(() => setDisplayClock(clock()), 1000);
    return () => {
      clearTimeout(timerId);
    };

  }, []);
  // CLOCK BLOCK END
  const currentDate = currentDay().charAt(0).toUpperCase() + currentDay().slice(1);
  const currentDateValue = currentDate.substring(0, 3);

  const statusCards = [
    {
      title: "All",
      flag : "N",
      color: "#fff",
    },
    {
      title: "Reserved",
      status: <Badge color="#f2e8cf" text="Reserved" style={data.restaurantPos.tableManagement.statusTitle} />,
      color: "#f2e8cf",
      flag : reserveFlag ==="N" ? "N" : "Y"
    },
    {
      title: "Occupied",
      status: <Badge color="#bc4749" text="Occupied" style={data.restaurantPos.tableManagement.statusTitle} />,
      color: "#bc4749",
      flag : "Y"
    },
    {
      title: "Open",
      color: "#a7c957",
      status: <Badge color="#a7c957" text="Open" style={data.restaurantPos.tableManagement.statusTitle} />,
      flag : "Y"
    },
    {
      title: "Waiting",
      status: <Badge color="#FFA85B" text="Waiting" style={data.restaurantPos.tableManagement.statusTitle} />,
      color: "#FFA85B",
      flag : "N"
    },
    {
      title: "No Service",
      status: <Badge color="#000000" text="No Service" style={data.restaurantPos.tableManagement.statusTitle} />,
      color: "#000000",
      flag : "N"
    },
  ];

  const orderStatus = [
    {
      title: "All",
      flag : "N",
      color: "#fff",
    },
    {
      title: "Pending",
      status: <Badge color="#bc4749" text="Occupied" style={data.restaurantPos.tableManagement.statusTitle} />,
      color: "#bc4749",
      flag : "Y"
    },
    {
      title: "Completed",
      color: "#a7c957",
      status: <Badge color="#a7c957" text="Open" style={data.restaurantPos.tableManagement.statusTitle} />,
      flag : "Y"
    },
  ];

  const reservedTablesList = [];
  const [displayMergeTablesPopup, setDisplayMergeTablesPopup] = useState(false);
  const [mergeTablesSelection, setMergeTablesSelection] = useState(false);

  const handleMergeTables = () => {
    setMergeTablesSelection(true);
    // setDisplayMergeTablesPopup(true);
  };

  const handleTable = async(record) => {
    setLoading({ status: true, message: "" });
    const tokens = JSON.parse(localStorage.getItem("tokens"));
    // record.orderId = orderId;
    if (mergeTablesSelection === true) {
      history.push("/table-management");
    } else {
      setRecardState(record);
     let FbTableData = await Axios({
        url: serverUrl,
        method: "POST",
        data: {
          query: `query {
            getFBOrder(tableId:"${record?.cwrFbTableId}",
            status: "IP") {
            cSBunitID
            cSClientID
            isactive
            fbOrderId
            fbTableId
            fbSectionId
            sOrderId
            orderNo
            guestName
            guestType
            referredBy
            date1
            status
            salesRepId
            guests
            lines{
            cSBunitID
            cSClientID
            isactive
            fbOrderId
            fbOrderLineId
            mPoductId
            mBatchId
            description
            csUomId
            csTaxId
            line
            qty
            unitPrice
            listPrice
            lineNet
            lineTax
            lineGross
            sOrderLineId
            isOrdered
            meta{
              key
              value
              fbOrderLineMetaId
              }
            }
            }
        }`,
        },
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${tokens.token_type} ${tokens.access_token}`,
        },
      });
     mainTableData.map(async (item) => {
        if(record.cwrFbTableId === item.cwrFbTableId){
          if(FbTableData.data.data.getFBOrder.length>0 || record.statusType === "OCU"){
                if(FbTableData.status === 200){
                  if(FbTableData.data.data?.getFBOrder.length>0){
                    record.waiter = FbTableData.data.data?.getFBOrder[0].salesRepId;
                    record.data = FbTableData.data.data?.getFBOrder[0];
                    record.guestType = FbTableData.data.data?.getFBOrder[0].guestType;
                    record.guestName = FbTableData.data.data?.getFBOrder[0].guestName;
                    record.noOfPersons = FbTableData.data.data?.getFBOrder[0].guests;
                    record.referredBy = FbTableData.data.data?.getFBOrder[0].referredBy;
                    localStorage.setItem("tableName", JSON.stringify(record));
                    setLoading({ status: false, message: "" });
                    history.push({
                      pathname: "/pos",
                      state: { table: record?.title || "",id: record?.cwrFbTableId || "" ,noOfPersons:record?.noOfPersons || ""},
                    });
                  }
                }
          }else{
            if(!item.noOfPersons){
              if (inputRef.current) {
                inputRef.current.focus();
              }
              if(tillData.tillAccess.salesRep === "Y"){
                setLoading({ status: false, message: "" });
              setGuestsModalFlag(true);
              }else{
                setLoading({ status: false, message: "" });
              }
              
            }
            else{
              await Axios({
                url: serverUrl,
                method: "POST",
                data: {
                  query: `query {getTableReservation(tableId: "${record?.cwrFbTableId}", date : null) {
                    cSBunitID
                    cSClientID
                    isactive
                    fbTableReservationId
                    fbTableId
                    fbSectionId
                    customerName
                    noOfPeople
                    contactNo
                    guestType
                    referredBy
                    reservationTime
                    reservationDate
                    }
                }`,
                },
                headers: {
                  "Content-Type": "Application/json",
                  Authorization: `${tokens.token_type} ${tokens.access_token}`,
                },
              }).then(async(response) => {
                if(response.status === 200){
                  record.waiter = tillData.tillAccess.salesRepId;
                  record.data = response.data.data?.getTableReservation[0];
                  record.guestType = response.data.data?.getTableReservation[0].guestType;
                  record.guestName = response.data.data?.getTableReservation[0].customerName;
                  record.noOfPersons = response.data.data?.getTableReservation[0].noOfPeople;
                  record.referredBy = response.data.data?.getTableReservation[0].referredBy;
                  record.fbtableStatusId = record.fbtableStatusId === undefined ? uuidv4().replace(/-/g, "").toUpperCase() : record.fbtableStatusId;
                  localStorage.setItem("tableName", JSON.stringify(record));
                  const tokens = JSON.parse(localStorage.getItem("tokens"));
                  const paramsInput = {
                    query: `mutation {
                      upsertTableStatus(tableStatus: {
                         cSBunitID: "${tillData.tillAccess.csBunit.csBunitId}"
                        fbtableStatusId: "${record.fbtableStatusId}"
                        fbTableId: "${record.cwrFbTableId}"
                        fbSectionId: "${record.cwrFbsectionId}"
                        status: "OCU"
                        guests: ${record.noOfPersons}
                        guestName: "${record.guestName === undefined ? "" : record.guestName}"
                        guestType: "family"
                        referredBy: "${record.referredBy === undefined ? "" : record.referredBy}"
                        salesRepId: "${tillData.tillAccess.salesRepId}"
                       }) { 
                    status
                    message   
                    }
                    }`,
                  };
                 await Axios({
                    url: serverUrl,
                    method: "POST",
                    data: paramsInput,
                    headers: {
                      "Content-Type": "Application/json",
                      Authorization: `${tokens.token_type} ${tokens.access_token}`,
                    },
                  }).then((response) => {
                    setLoading({ status: false, message: "" });
                    if(response.data.data.upsertTableStatus.status === "200"){
                      history.push({
                        pathname: "/pos",
                        state: { table: record?.title || "",id: record?.cwrFbTableId || "" ,noOfPersons:record?.noOfPersons || ""},
                      });
                    }
                  });
                }
              });
            }
          }
        }
      });
    }
  };

  const handleOrderData = () => {
    const uniqueId = uuidv4().replace(/-/g, "").toUpperCase();
    localStorage.setItem("tableName", JSON.stringify({ table: "TakeAway" || "",id: uniqueId || "" ,noOfPersons: "" ,sectionName:""}));
    history.push({
      pathname: "/pos",
      state: { table: "TakeAway" || "",id: uniqueId || "" ,noOfPersons: "" ,sectionName:""},
    });
  };

  const handleSelect = (key) => {
    let tempData = [];
    if(key === "all"){
      tempData.push(...mainTableData);
      setRadioValue({value:"all",name:"All"});
    }else{
      mainTableData.map(e=>{
        if(e.cwrFbsectionId === key){
          tempData.push(e);
          setRadioValue({value:key,name:e.sectionName,noOfPersons:recordState?.noOfPersons || ""});
        }
      });
    }
    setTableList(tempData);
    setTableCards(tempData);
    setRadioFlag(false);
  };

  const handleOnChange = (key) => {
    let tempData = [];
    if(key === "all"){
      tempData.push(...mainTableData);
    }else{
      mainTableData.map(e=>{
        if(e.cwrFbsectionId === key){
          tempData.push(e);
        }
      });
    }
    setTableList(tempData);
  };

  const getCustomerData = (values)=>{
    setLoading({ status: true, message: "" });
    mainTableData.map(item => {
      if(recordState.cwrFbTableId === item.cwrFbTableId){
        item.noOfPersons = values.noOfGuests;
        item.guestName = values.guestName;
        item.referredBy = values.referredBy;
        item.waiter = values.waiter;
        item.fbtableStatusId = item.fbtableStatusId === undefined ? uuidv4().replace(/-/g, "").toUpperCase() : item.fbtableStatusId;
        item.guestType =  priorityBtn ? "single" : "family",
        localStorage.setItem("tableName", JSON.stringify(item));
        let obj = {
          guestName:values.guestName,
          noOfPersons:values.noOfGuests,
          waiter:values.waiter,
          referredBy: values.referredBy,
          table: recordState?.title,
          id: recordState?.cwrFbTableId,
        }
        
        const tokens = JSON.parse(localStorage.getItem("tokens"));
        const paramsInput = {
          query: `mutation {
            upsertTableStatus(tableStatus: {
               cSBunitID: "${tillData.tillAccess.csBunit.csBunitId}"
              fbtableStatusId: "${item.fbtableStatusId}"
              fbTableId: "${recordState.cwrFbTableId}"
              fbSectionId: "${recordState.cwrFbsectionId}"
              status: "OCU"
              guests: ${values.noOfGuests}
              guestName: "${values.guestName === undefined ? "" : values.guestName}"
              guestType: "${priorityBtn ? "single" : "family"}"
              referredBy: "${values.referredBy === undefined ? "" : values.referredBy}"
              salesRepId: "${tillData.tillAccess.salesRepId}"
             }) { 
          status
          message   
          }
          }`,
        };
        Axios({
          url: serverUrl,
          method: "POST",
          data: paramsInput,
          headers: {
            "Content-Type": "Application/json",
            Authorization: `${tokens.token_type} ${tokens.access_token}`,
          },
        }).then((response) => {
          if(response.data.data.upsertTableStatus.status === "200"){
            setLoading({ status: false, message: "" });
            history.push({
              pathname: "/pos",
              state: obj,
            });
          }
        });
      }
    });

  };

  const onChangeInput = event => {
    const inputVal = event.target.value;
    setInputFields({
      ...inputFields,
      [inputName]: inputVal
    });
  };

  const handleKeyboardInputs = (button) => {
    if (button === "{shift}" || button === "{default}" ) setLayout(layout === "default" ? "shift" : "default");
    if (button === "{caps}") setLayout("shift");
    if (button === "{small}") setLayout("default");
    if (button === "{numbers}") setLayout("numbers");
    if (button === "{number}") setLayout("number"); 
  };

  const onChange = (inputs) => {
    setInputFields({ ...inputs });
    guestForm.setFieldsValue(inputs)
};

const onChangeCompany = (inputs) => {
  setInputFields({ ...inputs });
  setCompanySeacrhInput(inputs.customerSearchInput);
  getCompanyData(inputs.customerSearchInput);
};

  const [form] = Form.useForm();
  const [displayReserveTablesPopup, setDisplayReserveTablesPopup] = useState(false);

  const handleReserveTable = () => {
    let newTime = moment(new Date());
    form.setFieldsValue({section:radioValue.value,time:newTime});
    setDisplayReserveTablesPopup(true);
  };

  const handleReserveTableDetails = async(values) => {
    let reservedTables = [];
    mainTableData.map((item)=>{
      let value = values.tableNum.split("-");
      if(value[0] === item.cwrFbTableId){
        const uniqueId = uuidv4().replace(/-/g, "").toUpperCase();
        item.status = <Badge color="#f2e8cf" />;
        item.color = "#f2e8cf";
        item.noOfPersons = values.noOfPersons;
        item.waiter = tillData.tillAccess.salesRepId;
        item.reservedTime = moment(values.time).format("YYYY-MM-DD hh:mm:ss");
        item.fbtableStatusId =  item.fbtableStatusId === null || item.fbtableStatusId === undefined ? uniqueId : item.fbtableStatusId;
        reservedTables.push(item);
      }
    });
    let tableId = values?.tableNum.split("-");
    const paramsInput = {
      query: `mutation {
        upsertTableReservation(tableReservation: {
          cSBunitID: "${tillData.tillAccess.csBunit.csBunitId}"
          fbTableReservationId: "${uuidv4().replace(/-/g, "").toUpperCase()}"
          fbTableId: "${tableId[0]}"
          fbSectionId: "${tableId[1]}"
          customerName: "${values.name === undefined ? "" : values.name}"
          noOfPeople: "${values.noOfPersons}"
          contactNo: "${values.mobile === undefined ? "" : values.mobile}"
          guestType: "family"
          referredBy: "vehicles"
          resDate: "${moment(values.time).format("YYYY-MM-DD")}"
          resTime: "${moment(values.time).format("YYYY-MM-DD hh:mm:ss")}"
         }) { 
      status
      message   
      }
      }`,
    };
    Axios({
      url: serverUrl,
      method: "POST",
      data: paramsInput,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    }).then((response) => {
      if(response.data.data.upsertTableReservation.status === "200"){
        form.resetFields();
        const updateStatus = {
          query: `mutation {
            upsertTableStatus(tableStatus: {
               cSBunitID: "${tillData.tillAccess.csBunit.csBunitId}"
              fbtableStatusId: "${reservedTables[0].fbtableStatusId}"
              fbTableId: "${tableId[0]}"
              fbSectionId: "${tableId[1]}"
              status: "RES"
              guests: ${values.noOfPersons}
              guestName: ${values.name === undefined ? null : `"${values.name}"`}
              guestType: "${priorityBtn ? "single" : "family"}"
              referredBy: ${values.referredBy === undefined ? null : `"${values.referredBy}"`}
              salesRepId: "${tillData.tillAccess.salesRepId}"
             }) { 
          status
          message   
          }
          }`,
        };
        Axios({
          url: serverUrl,
          method: "POST",
          data: updateStatus,
          headers: {
            "Content-Type": "Application/json",
            Authorization: `${tokens.token_type} ${tokens.access_token}`,
          },
        }).then((response) => {
          if(response.data.data.upsertTableStatus.status === "200"){
            setDisplayReserveTablesPopup(false);
          }
        });
      }
    });
  };

  const handleRedirectDash = () => {
    history.push("/dashboard");
  };

  const handleSelectedData = (item) => {
    let tempData = [];
    mainTableData.map(key => {
      if(radioValue.value === "all"){
        if(key.color === item.color){
          tempData.push(key);
        };
      }else{
        if(key.color === item.color && key.sectionName === radioValue.name){
          tempData.push(key);
        };
      }
    });
    setTableCards(tempData);
  };

  const getCompanyData = (value) => {
    return new Promise(function (resolve) {
      Axios({
        url: serverUrl,
        method: "POST",
        data: {
          query: `query{
            customerSearch(name:"${value}"){
              cwrCustomerId 
              code   
              name
              email        
              mobileNo
              name
              pincode
              retlLoyaltyBalance
              b2cRegisteredstoreId
               iscredit
              loyaltyLevel{
                  cwrLoyaltyLevelId
                  name
                  accumulationRate
                  redemptionRate
              }
              sCustomer{
                sCustomerID
                customerCategory{
                  sCustomerCateforyId
                  value
                  name
                  description
                }
              }
              csBunit{
                csBunitId
                name
              }
            }
            
          }`,
        },
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${tokens.token_type} ${tokens.access_token}`,
        },
      })
        .then((response) => {
          if (response.data.data.customerSearch !== null && response.data.data.customerSearch.length > 0) {
            const result = response.data.data.customerSearch;
            setCustomerSearchResults(result)
            resolve(result[0]);
          } else {
            console.warn("No customer found, try searching with different customer");
            resolve(null);
            setCustomerSearchResults([]);
          }
        })
        .catch(() => {
          resolve(null);
        });
    });
  };

  const openCompanyData = () => {
    setDisplayCompanySearch(true);
    companySearchInputRef?.current?.focus();
  }

  const selectCompanyData = (name) => {
    guestForm.setFieldsValue({referredBy:name});
    setCompanyName(name);
    setDisplayCompanySearch(false);
  }

  const antIcon = <LoadingOutlined style={{ fontSize: "24px" }} spin />;


  return (
    <div className="main mainOverFlow" style={data.restaurantPos.main}>
      <Spin indicator={antIcon} spinning={loading.status}>
      <Row
        style={{
          height: `${3.7 * dynamicHeight}vw`,
          padding: data.restaurantPos.header.padding,
        }}
      >
        <Col span={1}>
          <img src={MenuIcon} style={data.restaurantPos.header.menuIcon} alt="" />
        </Col>
        <Col span={5}>
          <label style={data.restaurantPos.header.posHeadLabel}>{tillData.tillAccess.csBunit.name}</label>
        </Col>
        <Col span={10}>
          <label style={data.restaurantPos.header.timeFmt}>{tillData.tillAccess.cwrTill.till}</label>
        </Col>
        <Col span={4} style={data.restaurantPos.header.right}>
          <label style={data.restaurantPos.header.time}>{displayClock}</label>
        </Col>
        <Col span={4} style={data.restaurantPos.header.right}>
          <img onClick={handleRedirectDash} src={cwPosLogo} style={data.restaurantPos.header.posLogoIcon} alt="" />
        </Col>
      </Row>
      <Row style={data.restaurantPos.tableManagement.mainRowTable} className="mainRow">
        <Col span={24} style={{ paddingRight: 5 }}>
          <Card style={data.restaurantPos.tableManagement.mainCardTable} bodyStyle={{ padding: 0,height:"100%" }}>
            <Row style={data.restaurantPos.tableManagement.subHeadingRow}>

              <Col span={8} style={data.restaurantPos.tableManagement.leftAlign}>
                <strong>Table Management</strong>
              </Col>
              <Col span={8} style={{alignSelf:"center",textAlign:"center"}}>
              <strong style={dineInFlag !== "Y" ? {cursor:"pointer",marginRight:"20%"} : {cursor:"pointer" ,marginRight:"20%" ,boxShadow: "1px 1px 6px -3px #000000" ,padding:"5px" ,backgroundColor:"white",color:"#000000",borderRadius:"2px"}} onClick={()=> {setDineInFlag("Y");localStorage.setItem("dineIn","Y")}}>Dine-In</strong>
             {tillData.tillAccess.salesRep === "N" ? <strong onClick={()=> {setDineInFlag("N");localStorage.setItem("dineIn","N")}} style={dineInFlag !== "N" ? {cursor:"pointer"} : {cursor:"pointer",boxShadow: "1px 1px 6px -3px #000000" ,padding:"5px" ,backgroundColor:"white",color:"#000000",borderRadius:"2px"}} >Take Away</strong> : null}
              </Col>
              {dineInFlag !== "N" ?<Col span={2} style={{alignSelf:"center",textAlign:"center"}}>
              <strong>Section</strong>
              </Col> : <Col span={2}></Col>}
              {dineInFlag !== "N" ?<Col span={3} style={{alignSelf:"center",textAlign:"right"}}>
                  <Card bodyStyle={{ padding: 5 }} style={{ boxShadow: "1px 1px 6px -3px #000000" ,textAlign:"center",cursor:"pointer"}} onClick={() => {setRadioFlag(true);}}>
                    <div style={data.restaurantPos.tableManagement.statusDiv}>
                      <span>{radioValue.name}</span>
                    </div>
                  </Card>
              </Col> : <Col span={3} style={{alignSelf:"center",textAlign:"right",visibility:"hidden"}}>
                  <Card bodyStyle={{ padding: 5 }} style={{ boxShadow: "1px 1px 6px -3px #000000" ,textAlign:"center",cursor:"pointer"}} onClick={() => {setRadioFlag(true);}}>
                    <div style={data.restaurantPos.tableManagement.statusDiv}>
                      <span>{radioValue.name}</span>
                    </div>
                  </Card>
              </Col>}
              <Col span={2} style={data.restaurantPos.tableManagement.rightAlign} className="productSpecImgCenter">
                <img src={Home} onClick={handleRedirectDash} alt="dashboard" className="productSpecImg" />
              </Col>
            </Row>
              {
                dineInFlag === "Y" ? <div style={data.restaurantPos.tableManagement.contentRow}>
                <Row gutter={[8, 0]} style={{ marginBottom: "0.5%" }}>
                  <Col span={16}>
                    <Row gutter={[8, 0]}>
                      {statusCards.map((item) => (
                        <>
                        {
                          item.flag === "Y" ?                   
                        <Col span={4}>
                          <Card bodyStyle={{ padding: 5 }} style={{ boxShadow: "1px 1px 6px -3px #000000" ,cursor:"pointer"}} onClick={() => {handleSelectedData(item);}}>
                            <div style={data.restaurantPos.tableManagement.statusDiv}>
                              {item.status ? <span>{item.status}</span> : <span style={data.restaurantPos.tableManagement.statusTitle}>{item.title} </span>}
                            </div>
                          </Card>
                        </Col>
                        :
                        ""
                        }
                        </>
                      ))}
                    </Row>
                  </Col>
                  <Col span={8}>
                    <Row gutter={[8, 0]}>
                      {reserveFlag === "N" ? "": 
                      <Col span={8} offset={16}>
                      <Button style={data.restaurantPos.tableManagement.tablebtns} onClick={handleReserveTable}>
                        <div style={data.restaurantPos.tableManagement.statusDiv}>
                          <span style={data.restaurantPos.tableManagement.buttonTitle}>Reserve Table</span>{" "}
                        </div>
                      </Button>
                      </Col>
                      }
                      {/* <Col span={8}>
                        <Button style={data.restaurantPos.tableManagement.tablebtns} onClick={handleMergeTables}>
                          <div style={data.restaurantPos.tableManagement.statusDiv}>
                            <span style={data.restaurantPos.tableManagement.buttonTitle}>Merge Tables</span>{" "}
                          </div>
                        </Button> 
                      </Col>
                      <Col span={8}>
                        <Button style={data.restaurantPos.tableManagement.tablebtns}>
                          <div style={data.restaurantPos.tableManagement.statusDiv}>
                            <span style={data.restaurantPos.tableManagement.buttonTitle}>Split Table</span>{" "}
                          </div>
                        </Button>
                      </Col> */}
                    </Row>
                  </Col>
                </Row>
                <Scrollbars autoHide className="tablesScroll">
                  <InfiniteScroll
                    dataLength={tableCards.length}
                    hasMore={true}
                    endMessage={
                      <p className="center">
                        <b>Yay! you have seen it all</b>
                      </p>
                    }
                    style={{ overflowX: "hidden" }}
                  >
                    <Row gutter={[4, 4]}>
                      {tableCards.map((item) => (
                        <Col span={item.merge ? 8 : 4}>
                          <Card bodyStyle={{ padding: "0px 5px 10px" }} style={{ borderRadius: "5px" ,backgroundColor: item.color }} onClick={() => handleTable(item)}>
                            <Row style={{ justifyContent: "end" }}>
                              <Col span={12}>
                                <div
                                  style={{
                                    display: mergeTablesSelection === true ? "block" : "none",
                                  }}
                                >
                                  <Checkbox></Checkbox>
                                </div>
                              </Col>
                              <Col span={12}>
                                <div style={data.restaurantPos.tableManagement.rightAlign}>{item.status}</div>
                              </Col>
                            </Row>
                            <Row style={{ justifyContent: "center" }}>
                              <div style={data.restaurantPos.tableManagement.tableImg}>{item.tableImg}</div>
                            </Row>
                            <Row style={{ justifyContent: "center" }}>
                              <div style={data.restaurantPos.tableManagement.tableTitle}>{item.title}</div>
                            </Row>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </InfiniteScroll>
                </Scrollbars>
              </div>
              : <div style={data.restaurantPos.tableManagement.contentRow}>
              <Row gutter={[8, 0]} style={{ marginBottom: "0.5%" }}>
                <Col span={16}>
                  {/* <Row gutter={[8, 0]}>
                    {orderStatus.map((item) => (
                      <>
                      {
                        item.flag === "Y" ?                   
                      <Col span={4}>
                        <Card bodyStyle={{ padding: 5 }} style={{ boxShadow: "1px 1px 6px -3px #000000" ,cursor:"pointer"}} onClick={() => {handleSelectedData(item);}}>
                          <div style={data.restaurantPos.tableManagement.statusDiv}>
                            {item.status ? <span>{item.status}</span> : <span style={data.restaurantPos.tableManagement.statusTitle}>{item.title} </span>}
                          </div>
                        </Card>
                      </Col>
                      :
                      ""
                      }
                      </>
                    ))}
                  </Row> */}

                </Col>
                <Col span={8}>
                    <Row gutter={[8, 0]}>
                      <Col span={8} offset={16}>
                      <Button style={data.restaurantPos.tableManagement.tablebtns} onClick={handleOrderData}>
                        <div style={data.restaurantPos.tableManagement.statusDiv}>
                          <span style={data.restaurantPos.tableManagement.buttonTitle}>New Order</span>{" "}
                        </div>
                      </Button>
                      </Col>
                    </Row>
                  </Col>
              </Row>
{/* 
              <Scrollbars autoHide className="tablesScroll">
                <InfiniteScroll
                  dataLength={tableCards.length}
                  hasMore={true}
                  endMessage={
                    <p className="center">
                      <b>Yay! you have seen it all</b>
                    </p>
                  }
                  style={{ overflowX: "hidden" }}
                >
                  <Row gutter={[4, 4]}>
                    {orderedData.map((item) => (
                      <Col span={item.merge ? 8 : 4}>
                      <Card bodyStyle={{ padding: "0px 5px 10px" }} style={{ borderRadius: "5px" ,backgroundColor: item.color }} >
                        <Row style={{ justifyContent: "end" }}>
                          <Col span={12}>
                            <div
                              style={{
                                display: mergeTablesSelection === true ? "block" : "none",
                              }}
                            >
                              <Checkbox></Checkbox>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div style={data.restaurantPos.tableManagement.rightAlign}>{item.status}</div>
                          </Col>
                        </Row>
                        <Row style={{ justifyContent: "center" }}>
                          <div style={data.restaurantPos.tableManagement.tableImg}>No of Items - {item.cart.items.length}</div>
                        </Row>
                        <Row style={{ justifyContent: "center" }}>
                          <div style={data.restaurantPos.tableManagement.tableTitle}>Order ID - {item.orderId}</div>
                        </Row>
                      </Card>
                      </Col>
                    )
                    )}
                  </Row>
                </InfiniteScroll>
              </Scrollbars> */}
            </div>
              }
          </Card>
        </Col>
      </Row>

      {/* MERGE TABLES POPUP STARTS */}
      <Modal visible={displayMergeTablesPopup} closable={false} footer={null} bodyStyle={{ padding: 20 }} width="30%" style={{ top: 200 }}>
        <div>
          <Row style={{ paddingTop: 10 }}>
            <Col span={24}>
              <p style={data.restaurantPos.tableManagement.mergeTables.modalTitle}>Are you sure want to Merge the Tables?</p>
            </Col>
          </Row>
          <Row style={data.restaurantPos.tableManagement.mergeTables.yesCancelBtns}>
            <Button
              size="medium"
              className="customerDetailsSubmitBtnBtn"
              style={data.restaurantPos.tableManagement.mergeTables.mergeTablesSubmitBtn}
              htmlType="submit"
              onClick={() => setDisplayMergeTablesPopup(false)}
            >
              Yes
            </Button>
            <Button
              size="medium"
              className="customerDetailsCancelBtn"
              style={data.restaurantPos.tableManagement.mergeTables.mergeTablesCancelBtn}
              onClick={() => setDisplayMergeTablesPopup(false)}
            >
              No
            </Button>
          </Row>
        </div>
      </Modal>
      {/* MERGE TABLES POPUP ENDS */}

      {/* RESERVE TABLE MODAL BEGIN */}
      <Modal
        title={
          <div>
            <Row>
              <Col span={24}>
                <p style={data.restaurantPos.tableManagement.reserveTableModal.modalTitle}>Reserve Table</p>
              </Col>
            </Row>
          </div>
        }
        visible={displayReserveTablesPopup}
        centered
        onCancel={()=>{setDisplayReserveTablesPopup(false)}}
        footer={null}
        width="85%"
        closable={false}
        bodyStyle={{ padding: "10px 20px" }}
        style={{ top: 50 }}
      >
        <Form key={"reserveTable"} form={form} name="reserveTable" onFinish={handleReserveTableDetails}>
          <Row gutter={[16,16]}>
            <Col span={6} >
            <Row gutter={[16, 16]} style={{marginBottom:"15px"}}>
              <Col span={11} ><Button className="section-btn" onClick={()=>setPriorityBtn(false)} style={{backgroundColor:priorityBtn?"#fff":"rgb(81, 173, 154)",color:priorityBtn?"#000":"#fff"}}>Family</Button></Col>  
              <Col span={2}/> 
              <Col span={11} ><Button className="section-btn" onClick={()=>setPriorityBtn(true)} style={{backgroundColor:!priorityBtn?"#fff":"rgb(81, 173, 154)",color:!priorityBtn?"#000":"#fff"}}>Others</Button></Col>      
        </Row>
          <Row gutter={[0, 16]}>
            <Col span={24}>
              <label style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsField}>Name</label>
              <Form.Item name="name" style={data.restaurantPos.tableManagement.reserveTableModal.formItem}>
                <Input 
                readOnly
                ref={inputRef}
                onFocus={() => {setInputName("name");setLayout("default");}}
                placeholder="Enter Name" style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsInput} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <label style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsField}>Contact Number</label>
              <Form.Item name="mobile" style={data.restaurantPos.tableManagement.reserveTableModal.formItem}>
                <Input
                readOnly
                ref={inputRef}
                onFocus={() => {setInputName("mobile");setLayout("number");}}
                placeholder="Enter Contact Number" style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsInput} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <label style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsField}>Time</label>
              <Form.Item name="time" style={data.restaurantPos.tableManagement.reserveTableModal.formItem}>
               <DatePicker
                className = "timePicker"
                style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsInput}
                showTime={{ format: 'HH:mm' }}
                format="YYYY-MM-DD HH:mm"
                placeholder="Select Date"
              />
              </Form.Item>
            </Col>
            <Col span={24}>
              <label style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsField}><span style={{color:"red",marginLeft:"-9px",paddingRight:"3px"}}>*</span>No. of persons</label>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Please Enter No of Persons',
                    
                  },
                ]} 
               name="noOfPersons" style={data.restaurantPos.tableManagement.reserveTableModal.formItem}>
                <Input
                readOnly
                ref={inputRef}
                onFocus={() => {setInputName("noOfPersons");setLayout("num");}}
                placeholder="Enter No of Persons" style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsInput} />
              </Form.Item>
            </Col>
          </Row>
            </Col>
            <Col span={6}>
              <Row gutter={[0,16]} style={{marginTop:"10vh"}}>
            <Col span={24}>
              <label style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsField}>Section</label>
              <Form.Item name="section" style={data.restaurantPos.tableManagement.reserveTableModal.formItem}>
                <Select
                  style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsInput}
                  showSearch
                  placeholder="Section"
                  optionFilterProp="children"
                  value={radioValue.value}
                  disabled = {radioValue.value !== "all" ? true : false}
                  onChange={handleOnChange}
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {
                    selectData.map(item => (<Option value={item.value}> {item.name} </Option>))
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <label style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsField}>Table Number</label>
              <Form.Item name="tableNum"
                rules={[
                  {
                    required: true,
                    message: 'Please Select Table',
                    
                  },
                ]} 
              style={data.restaurantPos.tableManagement.reserveTableModal.formItem}>
                <Select
                  style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsInput}
                  showSearch
                  placeholder="Select Table"
                  optionFilterProp="children"
                  // onChange={onChange}
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {tableList.map((tableObj) => (
                    <Option key={`${tableObj.cwrFbTableId}-${tableObj.cwrFbsectionId}`} value={`${tableObj.cwrFbTableId}-${tableObj.cwrFbsectionId}`}>
                      {tableObj.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="referredBy" style={data.restaurantPos.tableManagement.reserveTableModal.formItem}>
                {/* <Input placeholder="Referred By"
                style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsInput} /> */}
              <Row gutter={[16, 16]}>
                <Col span={11} ><a onClick={openCompanyData} style={{lineHeight:"1"}}>Select Company</a></Col> 
                <Col span={2}/> 
                <Col span={11} style={{textAlign:"center",alignSelf:"center",lineHeight:"1"}}><span>{companyName}</span></Col>      
              </Row>
              </Form.Item>
            </Col>
              </Row>
            </Col>
            <Col span={12}>
            {keyboardVisible && (
                            <Keyboard
                            buttonTheme={[
                              {
                                class: "hg-bksp",
                                buttons: "{bksp} {backspace}"
                              },
                              {
                                class: "hg-space",
                                buttons: "{space}"
                              }
                            ]}              
                            keyboardRef={r => (keyboardRef.current = r)}
                            inputName={inputName}
                            onChangeAll={(inputs)=>{
                              setInputFields({ ...inputs });
                              form.setFieldsValue(inputs);
                            }}
                            onKeyPress={handleKeyboardInputs}
                            layoutName={layout}
                            theme={"hg-theme-default signUp"}
                            display={{
                              "{abc}": "ABC",
                              "{smileys}": "\uD83D\uDE03",
                              "{numbers}": "123",
                              "{number}": "123",
                              "{shift}": "⇧",
                              '{tab}': 'tab',
                              '{lock}': 'lock',
                              '{enter}': 'enter',
                              '{space}': "Space",
                              "{caps}": "ABC",
                              "{small}": "abc",
                            }}
                            layout={{
                              shift: [
                                "q w e r t y u i o p",
                                "a s d f g h j k l",
                                "{shift} z x c v b n m {bksp}",
                                "{number} {space}"
                              ],
                              numbers: [
                                "1 2 3",
                                "4 5 6",
                                "7 8 9", 
                                "{caps} 0 {bksp}"
                              ],
                              number: [
                                "1 2 3",
                                "4 5 6",
                                "7 8 9",
                                "{small} 0 {bksp}", 
                              ],
                              default: [
                                "Q W E R T Y U I O P",
                                "A S D F G H J K L",
                                "{shift} Z X C V B N M {bksp}",
                                "{numbers} {space}"
                              ],
                              num: [
                                "1 2 3",
                                "4 5 6",
                                "7 8 9", 
                                " 0 {bksp}"
                              ],
                            }}
                          />
              )}
              <Row style={data.restaurantPos.tableManagement.reserveTableModal.reserveCancelBtns}>
                <Button
                  size="medium"
                  className="customerDetailsSubmitBtnBtn modal-btn"
                  style={data.restaurantPos.tableManagement.reserveTableModal.reserveTablesSubmitBtn}
                  htmlType="submit"
                  // onClick={() => setDisplayReserveTablesPopup(false)}  
                >
                  Okay
                </Button>
                <Button
                  size="medium"
                  className="customerDetailsCancelBtn modal-btn"
                  style={data.restaurantPos.tableManagement.reserveTableModal.reserveTablesCancelBtn}
                  onClick={() => {
                    setInputFields("");
                    setKeyboardVisible(false);
                    setTimeout(() => {
                      setKeyboardVisible(true);
                      setDisplayReserveTablesPopup(false)
                    }, 0);
                  }}
                >
                  Cancel
                </Button>
              </Row>
            </Col>
          </Row>

        </Form>
      </Modal>
      {/* RESERVE TABLE MODAL END */}


      {/* No Of Guests Confirmation Modal Start */}
      <Modal visible={guestsModalFlag} centered closable={false} footer={null} bodyStyle={{ padding: "20px" }} width="70%">
        <Row style={{ marginBottom: 10}} className="test">
          <Col span={16}>
            <p className="enter-receipt">Enter Number Guests</p>
          </Col>
        </Row>
        <Form form={guestForm} name="guestForm" onFinish={getCustomerData}>
          <Row>
            <Col span={9}>
            <Row gutter={[16, 16]} style={{marginBottom:"15px"}}>
              <Col span={11} ><Button className="section-btn" onClick={()=>setPriorityBtn(false)} style={{backgroundColor:priorityBtn?"#fff":"rgb(81, 173, 154)",color:priorityBtn?"#000":"#fff"}}>Family</Button></Col>  
              <Col span={2}/> 
              <Col span={11} ><Button className="section-btn" onClick={()=>setPriorityBtn(true)} style={{backgroundColor:!priorityBtn?"#fff":"rgb(81, 173, 154)",color:!priorityBtn?"#000":"#fff"}}>Others</Button></Col>      
            </Row>
            <Row gutter={[0, 16]}>
            <Col span={24}>
              <label style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsField}>Waiter</label>
              <Form.Item name="waiter" initialValue={tillData.tillAccess.salesRepId} style={data.restaurantPos.tableManagement.reserveTableModal.formItem}>
              <Select disabled>
                  <Option key={tillData.tillAccess.salesRepId} value={tillData.tillAccess.salesRepId}>
                    {localStorage.getItem("username")}
                  </Option>
              </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <label style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsField}><span style={{color:"red",marginLeft:"-9px",paddingRight:"3px"}}>*</span>No Of Guests</label>
              <Form.Item name="noOfGuests"
              rules={[
                      {
                        required: true,
                        message: 'Please enter No Of Guests',
                      },
                    ]} 
                  style={data.restaurantPos.tableManagement.reserveTableModal.formItem}>
                <Input placeholder="No Of Guests"
                readOnly
                ref={inputRef}
                onFocus={() => {setInputName("noOfGuests");setLayout("num");}}
                onChange={onChangeInput}        
                style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsInput} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <label style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsField}>Guest Name</label>
              <Form.Item name="guestName"
                onFocus={() => {setInputName("guestName");setLayout("default");}}
                onChange={onChangeInput}   
               style={data.restaurantPos.tableManagement.reserveTableModal.formItem}>
                <Input
                readOnly
                ref={inputRef}
                 placeholder="Guest Name" style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsInput} />
              </Form.Item>
            </Col>
            <Col span={24}>
              {/* <label style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsField}>Referred By</label> */}
              <Form.Item name="referredBy" style={data.restaurantPos.tableManagement.reserveTableModal.formItem}>
              <Row gutter={[16, 16]}>
                <Col span={11} ><a onClick={openCompanyData}>Select Company</a></Col> 
                <Col span={2}/> 
                <Col span={11} style={{textAlign:"center",alignSelf:"center"}}><span>{companyName}</span></Col>      
              </Row>
              </Form.Item>
            </Col>
            </Row>
            </Col>
            <Col span={1}/>
            <Col span={14} style={{marginTop:"1%"}}>
            {keyboardVisible && (
                            <Keyboard
                            buttonTheme={[
                              {
                                class: "hg-bksp",
                                buttons: "{bksp} {backspace}"
                              },
                              {
                                class: "hg-space",
                                buttons:"{space}"
                              }
                            ]}             
                            keyboardRef={r => (keyboardRef.current = r)}
                            inputName={inputName}
                            onChangeAll={onChange}
                            onKeyPress={handleKeyboardInputs}
                            layoutName={layout}
                            theme={"hg-theme-default signUp"}
                            display={{
                              "{abc}": "ABC",
                              "{smileys}": "\uD83D\uDE03",
                              "{numbers}": "123",
                              "{number}": "123",
                              "{shift}": "⇧",
                              '{tab}': 'tab',
                              '{lock}': 'lock',
                              '{enter}': 'enter',
                              '{space}': "Space",
                              "{caps}": "ABC",
                              "{small}": "abc",
                            }}
                            layout={{
                              shift: [
                                "q w e r t y u i o p",
                                "a s d f g h j k l",
                                "{shift} z x c v b n m {bksp}",
                                "{number} {space}"
                              ],
                              numbers: [
                                "1 2 3",
                                "4 5 6",
                                "7 8 9", 
                                "{caps} 0 {bksp}"
                              ],
                              number: [
                                "1 2 3",
                                "4 5 6",
                                "7 8 9",
                                "{small} 0 {bksp}", 
                              ],
                              default: [
                                "Q W E R T Y U I O P",
                                "A S D F G H J K L",
                                "{shift} Z X C V B N M {bksp}",
                                "{numbers} {space}"
                              ],
                              num: [
                                "1 2 3",
                                "4 5 6",
                                "7 8 9", 
                                " 0 {bksp}"
                              ],
                            }}
                          />
              )}
              <Row style={data.restaurantPos.tableManagement.reserveTableModal.reserveCancelBtns}>
                <Button
                  size="medium"
                  className="customerDetailsSubmitBtnBtn modal-btn"
                  style={data.restaurantPos.tableManagement.reserveTableModal.reserveTablesSubmitBtn}
                  htmlType="submit"
                  // onClick={() => setDisplayReserveTablesPopup(false)}  
                >
                  Okay
                </Button>
                <Button
                  size="medium"
                  className="customerDetailsCancelBtn modal-btn"
                  style={data.restaurantPos.tableManagement.reserveTableModal.reserveTablesCancelBtn}
                  onClick={() => {
                    setInputFields("");
                    setKeyboardVisible(false);
                    guestForm.resetFields();    
                    setTimeout(() => {
                      setKeyboardVisible(true);
                      setGuestsModalFlag(false);
                    }, 0);
                  }}
                >
                  Cancel
                </Button>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>
      {/* No Of Guests Confirmation Modal End */}

      {/* Radio Modal Start */}
      <Modal visible={radioFlag} title={"Section Table"} closable={true} footer={null} onCancel={() => {setRadioFlag(false);}} bodyStyle={{ padding: "15px" }} width="55%">
          <Row gutter={[16, 16]}>
              {selectData.map(item => (<Col span={6} ><Button className="section-btn" onClick={() => handleSelect(item.value)}>{item.name}</Button></Col>))}
          </Row>
      </Modal>
      {/* Radio Modal End */}  

      {/* Company Modal Start */}
      <Modal
          title={
          <div>
            <Row>
              <Col span={24}>
                <p style={data.restaurantPos.tableManagement.reserveTableModal.modalTitle}>Search Company</p>
              </Col>
            </Row>
          </div>
        }
        visible={displayCompanySearch}
        closable={true}
        footer={null}
        width="70%"
        onCancel={()=>setDisplayCompanySearch(false)}
        keyboard={false}
        bodyStyle={{ padding: 15 }}
      >

        <Row>
            <Col span={9}>
            <Input
          ref={companySearchInputRef}
          placeholder="Search"
          size="large"
          readOnly
          // style={data.posScreenStyles.customerModal.customerSearchInput}
          className="customerSearchInput"
          suffix={<SearchOutlined onClick={getCompanyData}/>}
          onFocus={() => {setCompanyInputName("customerSearchInput");setLayout("default");}}
          value={companySearchInput}
          onPressEnter={getCompanyData}
          onChange={(e) => {onChangeCompany}}
        />
        <Row style={{ paddingTop: 10 }}>
          <Col span={24}>
            {
              <div style={{"textAlign": "left"}}>
                <Card bordered bodyStyle={{ padding: 10 }} 
                          style={{
                          "maxHeight": "60vh",
                          "minHeight": "55vh",
                          "boxShadow": "0px 3px 6px #00000029",
                          "borderRadius": "5px",
                        }}
                >
                  <Scrollbars autoHide className="customerCardScroll">
                    {customerSearchResults.map((customerSearchResult, index) => (
                      <Card
                        tabIndex={0}
                        // onKeyPress={(e) => (e.charCode === 13 ? selectCustomer(index) : null)}
                        onClick={()=>{selectCompanyData(customerSearchResult.name);localStorage.setItem("customerName",JSON.stringify(customerSearchResult))}}
                        key={`${index}`}
                        bodyStyle={{ padding: 10 }}
                        style={{
                          "background": "#0C173A0d",
                          "marginBottom": "10px",
                        }}
                      >
                        <Row>
                          <Col span={24} >
                            <p >{customerSearchResult.name}</p>
                          </Col>
                        </Row>
                      </Card>
                    ))}
                  </Scrollbars>
                </Card>
              </div>}
          </Col>
        </Row>
            </Col>
            <Col span={1}/>
            <Col span={14} style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
            {keyboardVisible && (
                            <Keyboard
                            buttonTheme={[
                              {
                                class: "hg-bksp",
                                buttons: "{bksp} {backspace}"
                              },
                              {
                                class: "hg-space",
                                buttons: "{space}"
                              }
                            ]}              
                            keyboardRef={r => (keyboardRef.current = r)}
                            inputName={companyInputName}
                            onChangeAll={onChangeCompany}
                            onKeyPress={handleKeyboardInputs}
                            layoutName={layout}
                            theme={"hg-theme-default signUp"}
                            display={{
                              "{abc}": "ABC",
                              "{smileys}": "\uD83D\uDE03",
                              "{numbers}": "123",
                              "{number}": "123",
                              "{shift}": "⇧",
                              '{tab}': 'tab',
                              '{lock}': 'lock',
                              '{enter}': 'enter',
                              '{space}': "Space",
                              "{caps}": "ABC",
                              "{small}": "abc",
                            }}
                            layout={{
                              shift: [
                                "q w e r t y u i o p",
                                "a s d f g h j k l",
                                "{shift} z x c v b n m {bksp}",
                                "{number} {space}"
                              ],
                              numbers: [
                                "1 2 3",
                                "4 5 6",
                                "7 8 9", 
                                "{caps} 0 {bksp}"
                              ],
                              number: [
                                "1 2 3",
                                "4 5 6",
                                "7 8 9",
                                "{small} 0 {bksp}", 
                              ],
                              default: [
                                "Q W E R T Y U I O P",
                                "A S D F G H J K L",
                                "{shift} Z X C V B N M {bksp}",
                                "{numbers} {space}"
                              ],
                              num: [
                                "1 2 3",
                                "4 5 6",
                                "7 8 9", 
                                " 0 {bksp}"
                              ],
                            }}
                          />
              )}
              {/* <Row style={data.restaurantPos.tableManagement.reserveTableModal.reserveCancelBtns}>
                <Button
                  size="medium"
                  className="customerDetailsSubmitBtnBtn modal-btn"
                  style={data.restaurantPos.tableManagement.reserveTableModal.reserveTablesSubmitBtn}
                  htmlType="submit"
                  // onClick={() => setDisplayReserveTablesPopup(false)}  
                >
                  Okay
                </Button>
                <Button
                  size="medium"
                  className="customerDetailsCancelBtn modal-btn"
                  style={data.restaurantPos.tableManagement.reserveTableModal.reserveTablesCancelBtn}
                  onClick={() => {
                    setInputFields("");
                    setKeyboardVisible(false);
                    guestForm.resetFields();    
                    setTimeout(() => {
                      setKeyboardVisible(true);
                      setGuestsModalFlag(false);
                    }, 0);
                  }}
                >
                  Cancel
                </Button>
              </Row> */}
            </Col>
          </Row>
      </Modal>
      {/* Company Modal End */}  

      </Spin>
    </div>
  );
};
export default TableManagement;
